import React, {useState} from "react";
import DataTable from "../dataTable/DataTableBase";
import {useGetDrinkCategoriesQuery} from "./drinkCategoriesApiSlice";
import ReadDrinkCategoryModal from "../../components/DrinkCategory/ReadDrinkCategoryModal";
import UpdateDrinkCategoryModal from "./UpdateDrinkCategoryModal";
import DeleteDrinkCategoryModal from "./DeleteDrinkCategoryModal";

function DrinkCategoryList({handleModalHasChanged}) {
    const [baseUrl, setBaseUrl] = useState("drink-categories");
    const [url, setUrl] = useState(baseUrl);

    const {
        data: response,
        isLoading,
        isSuccess,
        isError,
        error,
    } = useGetDrinkCategoriesQuery({url});

    const columns = [
        {
            name: "",
            selector: "image",
            sortable: true,
            type: "image",
        },
        {
            name: "Nom",
            selector: "name",
            sortable: true,
        },
        {
            name: "Description",
            selector: "description",
        },
    ];

    let data = [];
    let meta = {};

    let content = "";
    if (isLoading) {
        content = <p>Loading ...</p>;
    } else if (isSuccess) {
        data = response.data.map((tag) => ({
            id: tag.id,
            name: tag.name,
            description: tag.description,
            image: tag.images[0]?.croppedImageUrl,
        }));

        meta = {
            currentPage: response.current_page,
            from: response.from,
            lastPage: response.last_page,
            perPage: response.per_page,
            total: response.total,
        };
    } else if (error) {
        content = <p>{JSON.stringify(error)}</p>;
    }

    const actions = (drinkCategory) => {
        return (
            <div className="fw-bold d-flex">
                <ReadDrinkCategoryModal drinkCategory={drinkCategory}/>
                <UpdateDrinkCategoryModal drinkCategory={drinkCategory}/>
                <DeleteDrinkCategoryModal drinkCategory={drinkCategory}/>
            </div>
        );
    };

    return (
        <div className="container-fluid">
            <DataTable
                columns={columns}
                data={data}
                actions={actions}
                baseUrl={baseUrl}
                setUrl={setUrl}
                meta={meta}
            />
        </div>
    );
}

export default DrinkCategoryList;
