import Title from "../components/Shared/Title";
import {Link} from "react-router-dom";
import {useState} from "react";
import React from "react";
import DeliveryPersonsList from "../features/deliveryPerson/DeliveryPersonsList";

function Staff() {
    const [isFilterVisible, setIsFilterVisible] = useState(false);
    return (
        <>
            <Title title="Livreurs"/>

            <div className="row d-none">
                {/* <!-- Card --> */}
                <div className="col-lg-3 mb-3 mb-lg-5">
                    <div className="d-grid gap-2 gap-lg-4">
            <span className="card card-hover-shadow">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <span
                        className="text-inherit avatar avatar-lg avatar-4x3"
                        style={{minHeight: "5rem", fontSize: "5vh"}}
                        data-hs-theme-appearance="default"
                    >
                      958
                    </span>
                  </div>

                  <div className="flex-grow-1 ms-4">
                    <h3 className="text-inherit mb-1 text-center"></h3>
                    <span className="text-body">Livreurs</span>
                  </div>

                  <div className="ms-2 text-end">
                    <i className="d-none bi-chevron-right text-body text-inherit"></i>
                  </div>
                </div>
              </div>
            </span>
                    </div>
                </div>
                {/* <!-- End Card --> */}

                {/* <!-- Card --> */}
                <div className="col-lg-3 mb-3 mb-lg-5">
                    <div className="d-grid gap-2 gap-lg-4">
            <span className="card card-hover-shadow">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <span
                        className="text-inherit avatar avatar-lg avatar-4x3"
                        style={{minHeight: "5rem", fontSize: "5vh"}}
                        data-hs-theme-appearance="default"
                    >
                      20
                    </span>
                  </div>

                  <div className="flex-grow-1 ms-4">
                    <h3 className="text-inherit mb-1 text-center"></h3>
                    <span className="text-body">Livreurs En Attente</span>
                  </div>

                  <div className="ms-2 text-end">
                    <i className="d-none bi-chevron-right text-body text-inherit"></i>
                  </div>
                </div>
              </div>
            </span>
                    </div>
                </div>
                {/* <!-- End Card --> */}

                {/* <!-- Card --> */}
                <div className="col-lg-3 mb-3 mb-lg-5">
                    <div className="d-grid gap-2 gap-lg-4">
            <span className="card card-hover-shadow">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <span
                        className="text-inherit avatar avatar-lg avatar-4x3"
                        style={{minHeight: "5rem", fontSize: "5vh"}}
                        data-hs-theme-appearance="default"
                    >
                      908
                    </span>
                  </div>

                  <div className="flex-grow-1 ms-4">
                    <h3 className="text-inherit mb-1 text-center"></h3>
                    <span className="text-body">Livreurs Approuvés</span>
                  </div>

                  <div className="ms-2 text-end">
                    <i className="d-none bi-chevron-right text-body text-inherit"></i>
                  </div>
                </div>
              </div>
            </span>
                    </div>
                </div>
                {/* <!-- End Card --> */}

                {/* <!-- Card --> */}
                <div className="col-lg-3 mb-3 mb-lg-5">
                    <div className="d-grid gap-2 gap-lg-4">
            <span className="card card-hover-shadow">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <span
                        className="text-inherit avatar avatar-lg avatar-4x3"
                        style={{minHeight: "5rem", fontSize: "5vh"}}
                        data-hs-theme-appearance="default"
                    >
                      30
                    </span>
                  </div>

                  <div className="flex-grow-1 ms-4">
                    <h3 className="text-inherit mb-1 text-center"></h3>
                    <span className="text-body">Livreurs Rejetés</span>
                  </div>

                  <div className="ms-2 text-end">
                    <i className="d-none bi-chevron-right text-body text-inherit"></i>
                  </div>
                </div>
              </div>
            </span>
                    </div>
                </div>
                {/* <!-- End Card --> */}
            </div>

            <div className="container-fluid mt-5 mb-5">
                <div className="d-none">
                    <button
                        type="button"
                        className="btn btn-white "
                        onClick={() => setIsFilterVisible(!isFilterVisible)}
                    >
                        <i className="bi-filter me-1"></i> Filtres
                    </button>
                </div>

                {isFilterVisible && (
                    <div className="row">
                        <div className="col-md-4 mt-3">
                            <input
                                type="text"
                                className="form-control"
                                id="eventLocationLabel"
                                placeholder="Nom du membre de staff"
                                aria-label="Nom du membre de staff"
                            />
                        </div>

                        <div className="col-md-4 mt-3">
                            <input
                                type="text"
                                className="form-control"
                                id="eventLocationLabel"
                                placeholder="Numero de telephone"
                                aria-label="Numero de telephone"
                            />
                        </div>

                        <div className="col-md-4 mt-3">
                            {/* <!-- Select --> */}
                            <div className="tom-select-custom">
                                <select
                                    className="js-select form-select w-full"
                                    autoComplete="off"
                                    id="eventColorLabel"
                                    data-hs-tom-select-options='{
                        "searchInDropdown": false,
                        "placeholder": "Select event color"
                      }'
                                >
                                    <option
                                        value=""
                                        data-option-template='<span className="d-flex align-items-center">Select event color</span>'
                                    >
                                        Statut
                                    </option>

                                    <option
                                        value="fullcalendar-custom-event-reminders"
                                        data-option-template='<span className="d-flex align-items-center"><span className="legend-indicator bg-danger me-2"></span>Reminders</span>'
                                    >
                                        En Attente
                                    </option>
                                    <option
                                        value="fullcalendar-custom-event-tasks"
                                        data-option-template='<span className="d-flex align-items-center"><span className="legend-indicator bg-warning me-2"></span>Tasks</span>'
                                    >
                                        Approuvés
                                    </option>

                                    <option
                                        value="fullcalendar-custom-event-tasks"
                                        data-option-template='<span className="d-flex align-items-center"><span className="legend-indicator bg-warning me-2"></span>Tasks</span>'
                                    >
                                        Rejetés
                                    </option>
                                </select>
                            </div>
                            {/* <!-- End Select --> */}
                        </div>

                        <div className="col-md-4 mt-3">
                            {/* <!-- Select --> */}
                            <div className="tom-select-custom">
                                <select
                                    className="js-select form-select w-full"
                                    autoComplete="off"
                                    id="eventColorLabel"
                                    data-hs-tom-select-options='{
                        "searchInDropdown": false,
                        "placeholder": "Select event color"
                      }'
                                >
                                    <option
                                        value=""
                                        data-option-template='<span className="d-flex align-items-center">Select event color</span>'
                                    >
                                        Notation
                                    </option>
                                    <option
                                        value="fullcalendar-custom-event-hs-team"
                                        data-option-template='<span className="d-flex align-items-center"><span className="legend-indicator bg-primary me-2"></span>HS Team</span>'
                                    >
                                        1 Etoile
                                    </option>
                                    <option
                                        value="fullcalendar-custom-event-reminders"
                                        data-option-template='<span className="d-flex align-items-center"><span className="legend-indicator bg-danger me-2"></span>Reminders</span>'
                                    >
                                        2 Etoiles
                                    </option>
                                    <option
                                        value="fullcalendar-custom-event-tasks"
                                        data-option-template='<span className="d-flex align-items-center"><span className="legend-indicator bg-warning me-2"></span>Tasks</span>'
                                    >
                                        3 Etoiles
                                    </option>
                                    <option
                                        value="fullcalendar-custom-event-holidays"
                                        data-option-template='<span className="d-flex align-items-center"><span className="legend-indicator bg-dark me-2"></span>Success</span>'
                                    >
                                        4 Etoiles
                                    </option>
                                </select>
                            </div>
                            {/* <!-- End Select --> */}
                        </div>

                        <div className="mt-3 d-flex bd-highlight justify-content-start">
                            <div className="mx-2">
                                <a className="btn btn-primary" href="#">
                                    Filtrer
                                </a>
                            </div>

                            <div className="mx-2">
                                <a className="btn btn-white" href="#">
                                    Réinitialiser
                                </a>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <div className="container-fluid d-flex justify-content-end bd-highlight">
                <Link to="/delivery-person/create">
                    <button
                        className="btn btn-lg btn-ghost-secondary btn-icon rounded-circle"
                        type="button"
                    >
                        <i className="bi bi-plus"></i>
                    </button>
                </Link>
            </div>
            <DeliveryPersonsList/>
        </>
    );
}

export default Staff;
