import React, {useEffect, useState} from "react";
import DataTable from "../../dataTable/DataTableBase";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {useGetRestaurantMealsQuery} from "../restaurantsApiSlice";
import DeleteMealModal from "../../meals/DeleteMealModal";
import ValidateMealModal from "../../meals/ValidateMealModal";
import {selectSelectedRestaurant} from "../restaurantSlice";
import Swal from "sweetalert2";
import {useToggleMealMutation} from "../../meals/mealsApiSlice";

function RestaurantMealList() {
    const selectedRestaurant = useSelector(selectSelectedRestaurant);

    const [toggleMeal] = useToggleMealMutation();

    const [baseUrl, setBaseUrl] = useState(
        `my-meals/restaurant/${selectedRestaurant.id}`
    );
    const [url, setUrl] = useState(baseUrl);

    useEffect(() => {
        console.log("SELECTED RESTAURANT", selectedRestaurant?.id);
    }, []);

    const {
        data: response,
        isLoading,
        isSuccess,
        isError,
        error,
    } = useGetRestaurantMealsQuery({url});

    const columns = [
        {
            name: "",
            selector: "image",
            sortable: true,
            type: "image",
        },
        {
            name: "Nom",
            selector: "name",
            sortable: true,
        },
        {
            name: "Disponible",
            selector: "is_available",
            type: "boolean",
        },
        {name: "Description", selector: "description", sortable: true},
    ];

    let data = [];
    let meta = {};

    let content = "";
    if (isLoading) {
        content = <p>Loading ...</p>;
    } else if (isSuccess) {
        data = response.data.map((meal) => ({
            ...meal,
            image: process.env.REACT_APP_BACK_END + meal.images[0]?.path?.path,
        }));

        meta = {
            currentPage: response.current_page,
            from: response.from,
            lastPage: response.last_page,
            perPage: response.per_page,
            total: response.total,
        };
    } else if (error) {
        content = <p>{JSON.stringify(error)}</p>;
    }

    const toggleMealFunction = (is_available, id) => {
        Swal.fire({
            title: "Confirmation",
            text: `Voulez-vous vraiment ${is_available ? "désactiver" : 'activer'}  ce repas ?`,
            icon: "question",
            confirmButtonText: "Oui",
            denyButtonText: "Non",
            showDenyButton: true,
        }).then((result) => {
            if (result.isConfirmed){
                Swal.fire({
                    title: "Traitement...",
                    text: "Veuillez patienter pendant le traitement de votre requête",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                toggleMeal(id)
                    .unwrap()
                    .then((response) => {
                        Swal.fire({
                            title: "Succees",
                            text: `${is_available ? "Désactivation" : "Activation"} effectuée avec succès`,
                            icon: "success",
                            toast: true,
                            position: "top-end",
                            timer: 3000,
                            timerProgressBar: true,
                        });
                    })
                    .catch((response) => {
                        Swal.fire({
                            title: "Erreur",
                            text: `Erreur lors de la ${is_available ? "désactivation" : "activation"} du repas`,
                            icon: "error",
                            toast: true,
                            position: "top-end",
                            timer: 3000,
                            timerProgressBar: true,
                        });
                    });
            }else{
                Swal.close()
            }
        })
    }

    const actions = (meal) => {
        return (
            <div className="fw-bold d-flex">
                {!meal.validated_by && <ValidateMealModal meal={meal}/>}

                <button
                    onClick={() => toggleMealFunction(meal.is_available, meal.id)}
                    title={`${meal.is_available ? "Désactiver" : "Activer"} le repas`}
                    className="btn btn-ghost-secondary btn-icon rounded-circle btn-lg"
                    type="button"
                >
                    <i className="bi bi-arrow-left-right"></i>
                </button>

                <Link to={`/meal/${meal.id}`}>
                    <button
                        className="btn btn-ghost-secondary btn-icon rounded-circle btn-lg"
                        type="button"
                    >
                        <i className="bi bi-eye fs-3"></i>
                    </button>
                </Link>

                <Link to={`/meal/edit/${meal.id}`}>
                    <button
                        className="btn btn-ghost-secondary btn-icon rounded-circle btn-lg"
                        type="button"
                    >
                        <i className="bi bi-pencil fs-3"></i>
                    </button>
                </Link>
                <DeleteMealModal meal={meal}/>
            </div>
        );
    };

    return (
        <>
            <div className="container-fluid d-flex justify-content-end bd-highlight">
                <div className="m-4">
                    <Link to="/meal/create">
                        <button
                            className="btn btn-ghost-secondary btn-icon rounded-circle btn-lg d-none"
                            type="button"
                        >
                            <i className="bi bi-plus fs-3"></i>
                        </button>
                        <button
                            className="btn btn-lg btn-ghost-secondary border"
                            type="button"
                        >
                            <i className="bi-egg-fried"></i>
                            Créer Repas
                        </button>
                    </Link>
                </div>
            </div>
            <div className="container">
                <DataTable
                    columns={columns}
                    data={data}
                    actions={actions}
                    baseUrl={baseUrl}
                    setUrl={setUrl}
                    meta={meta}
                />
            </div>
        </>
    );
}

export default RestaurantMealList;
