import React, {useEffect, useState} from "react";
import {
    useAddSupplementMutation,
    useGetCreateSupplementDataQuery,
} from "./supplementsApiSlice";
import Swal from "sweetalert2";
import Select from "react-select";

function CreateSupplementModal() {
    const [name, setName] = useState("");
    const [price, setPrice] = useState(null);
    const [description, setDescription] = useState("");
    const [image, setImage] = useState(null);
    const [mealsOptions, setMealsOptions] = useState([]);
    const [meal, setMeal] = useState(null);

    const [addSupplement] = useAddSupplementMutation();

    const {
        data: response,
        isLoading,
        isSuccess,
        isError,
        error,
    } = useGetCreateSupplementDataQuery();

    useEffect(() => {
        if (isSuccess) {
            let formattedMeals = response.meals.map((meal) => ({
                label: meal.name,
                value: meal.id,
            }));
            setMealsOptions(formattedMeals);
        }
    }, [isSuccess]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("IMAGE ==>", image);

        await addSupplement({
            name,
            description,
            image,
        })
            .unwrap()
            .then((response) => {
                if (response?.error?.originalStatus === 500) {
                    Swal.fire({
                        title: "Erreur",
                        text: `Erreur du serveur lors de la création du supplément`,
                        icon: "error",
                        toast: true,
                        position: "top-end",
                        timer: 3000,
                        timerProgressBar: true,
                    });
                } else {
                    Swal.fire({
                        title: "Succees",
                        text: `Supplément créé avec succès`,
                        icon: "success",
                        toast: true,
                        position: "top-end",
                        timer: 3000,
                        timerProgressBar: true,
                    });
                }
            })
            .catch((response) => {
                Swal.fire({
                    title: "Erreur",
                    text: `Erreur lors de la création du supplément`,
                    icon: "error",
                    toast: true,
                    position: "top-end",
                    timer: 3000,
                    timerProgressBar: true,
                });
            });

        setName("");
        setDescription("");
        setPrice(0);
        setImage(null);
    };

    return (
        <div>
            <button
                className="btn btn-ghost-secondary btn-icon rounded-circle btn-lg"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#createMealCategoryModal"
            >
                <i className="bi bi-plus fs-3"></i>
            </button>

            <div
                className="modal fade"
                id="createMealCategoryModal"
                tabIndex="-1"
                aria-labelledby="accountUpdatePlanModalLabel"
                role="dialog"
                aria-hidden="true"
            >
                <div
                    className="modal-dialog modal-dialog-centered modal-xs"
                    role="document"
                >
                    <div className="modal-content">
                        {/* <!-- Header --> */}
                        <div className="modal-header">
                            <h4 className="modal-title" id="accountUpdatePlanModalLabel">
                                Création d'un supplément
                            </h4>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        {/* <!-- End Header --> */}

                        {/* <!-- Body --> */}
                        <div className="modal-body">
                            <div className="container-fluid">
                                <form
                                    className="row"
                                    onSubmit={handleSubmit}
                                    encType="multipart/form-data"
                                >
                                    <div className="col-md-12 mt-3">
                                        <label htmlFor="name" className="form-label">
                                            Nom
                                        </label>

                                        <input
                                            type="text"
                                            id="name"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            className="form-control"
                                            placeholder="Nom"
                                            aria-label="Nom"
                                        />
                                    </div>

                                    <div className="col-md-12 mt-3">
                                        <label htmlFor="description" className="form-label">
                                            Description
                                        </label>

                                        <input
                                            type="text"
                                            id="description"
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                            className="form-control"
                                            placeholder="Description"
                                            aria-label="Description"
                                        />
                                    </div>

                                    <div className="mt-5 d-flex bd-highlight justify-content-start">
                                        <div className="mx-2">
                                            <button
                                                className="btn btn-primary"
                                                type="submit"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                            >
                                                Créer
                                            </button>
                                        </div>

                                        <div className="mx-2">
                                            <button
                                                type="button"
                                                className="btn btn-white"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                            >
                                                Annuler
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        {/* <!-- End Body --> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreateSupplementModal;
