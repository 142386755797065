import React, {useState} from "react";
import service from "../../app/api/FetchInterceptor";
import {useSelector} from "react-redux";
import {selectSelectedRestaurant} from "../../features/restaurants/restaurantSlice";
import Swal from "sweetalert2";

const Withdraw = ({ loadWithdraws }) => {

    const selectedRestaurant = useSelector(selectSelectedRestaurant);
    const restaurantId = selectedRestaurant?.id;

    const [amount, setAmount] = useState(0);

    const [withrawing, setWithrawing] = useState(false)

    const withdraw = () => {
        setWithrawing(true)

        service({
            method: "POST",
            url: "withdrawals",
            data: {
                amount: amount,
                restaurant_id: restaurantId
            }
        }).then((response) => {
            setAmount(0)

            Swal.fire({
                icon: "success",
                position: "top-right",
                html: "Demande de retrait effectuée avec succès"
            })

            loadWithdraws()
        }).catch((error) => {

            Swal.fire({
                icon: "error",
                position: "top-right",
                html: error.response.data.message
            })

        }).finally(() => {
            setWithrawing(false)
        })
    }

    return (
        <>
            <div className="card p-3">
                <div className="card-header">
                    Demande de retrait
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col">
                            <input
                                className="form-control"
                                type="number"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}/>
                        </div>
                        <div className="col">
                            <button disabled={withrawing} className="btn btn-primary" onClick={withdraw}>Faire une demande</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Withdraw