import React from "react";
import Swal from "sweetalert2";
import {useNavigate} from "react-router-dom";
import {useValidateRestaurantMutation} from "./restaurantsApiSlice";

function ValidateRestaurantModal({restaurant}) {
    const [validateRestaurant] = useValidateRestaurantMutation();

    async function displayConfirmation() {
        const swal = await Swal.fire({
            title: "Confirmation",
            text: `Voulez-vous vraiment valider ${restaurant.name} ?`,
            icon: "question",
            confirmButtonText: "Oui",
            denyButtonText: "Non",
            showDenyButton: true,
        });
        const isValidateConfirmed = swal.isConfirmed;

        if (isValidateConfirmed) {
            validateRestaurant({restaurant_id: restaurant.id})
                .unwrap()
                .then(() => {
                    Swal.fire({
                        title: "Succees",
                        text: `${restaurant.name} validé avec succès`,
                        icon: "success",
                        toast: true,
                        position: "top-end",
                        timer: 3000,
                        timerProgressBar: true,
                    });
                })
                .catch((response) => {
                    Swal.fire({
                        title: "Erreur",
                        text: `Erreur lors de la validation de ${restaurant.name}`,
                        icon: "error",
                        toast: true,
                        position: "top-end",
                        timer: 3000,
                        timerProgressBar: true,
                    });
                });
        }
    }

    return (
        <div>
            <button
                className="btn btn-ghost-secondary btn-icon rounded-circle btn-lg"
                type="button"
                title="Valider"
                onClick={displayConfirmation}
            >
                <i className="bi bi-check2-square fs-3"></i>
            </button>
        </div>
    );
}

export default ValidateRestaurantModal;
