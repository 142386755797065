import Title from "../components/Shared/Title";
import {useState} from "react";
import OrderListing from "../components/Order/interface/OrderListing";
import React from "react";
import RestaurantOrderList from "../features/restaurants/orders/RestaurantOrderList";
import {useSelector} from "react-redux";
import {
    selectIdentity,
    selectRoles,
    selectStaff,
} from "../features/auth/authSlice";
import AdminOrderList from "../features/admin/AdminOrderList";
import {selectSelectedRestaurant} from "../features/restaurants/restaurantSlice";
import useRedirectUserIfNoRestaurantSelected from "../hooks/restaurants/useRedirectUserIfNoRestaurantSelected";
import DeliveryCompanyOrderList from "../features/deliveryCompany/orders/DeliveryCompanyOrderList";

function Order() {
    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const currentRoles = useSelector(selectRoles);
    const currentRolesNames = currentRoles?.map((role) => role.name);
    const currentIdentity = useSelector(selectIdentity);
    const currentStaff = useSelector(selectStaff);
    const selectedRestaurant = useSelector(selectSelectedRestaurant);
    const restaurantId = selectedRestaurant?.id;

    useRedirectUserIfNoRestaurantSelected();

    return (
        <>
            <Title title="Commandes"/>

            <div className="row d-none">
                {/* <!-- Card --> */}
                <div className="col-lg-3 mb-3 mb-lg-5">
                    <div className="d-grid gap-2 gap-lg-4">
            <span className="card card-hover-shadow">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <span
                        className="text-inherit avatar avatar-lg avatar-4x3"
                        style={{minHeight: "5rem", fontSize: "5vh"}}
                        data-hs-theme-appearance="default"
                    >
                      99
                    </span>
                  </div>

                  <div className="flex-grow-1 ms-4">
                    <h3 className="text-inherit mb-1 text-center"></h3>
                    <span className="text-body">Commandes</span>
                  </div>

                  <div className="ms-2 text-end">
                    <i className="d-none bi-chevron-right text-body text-inherit"></i>
                  </div>
                </div>
              </div>
            </span>
                    </div>
                </div>
                {/* <!-- End Card --> */}

                {/* <!-- Card --> */}
                <div className="col-lg-3 mb-3 mb-lg-5">
                    <div className="d-grid gap-2 gap-lg-4">
            <span className="card card-hover-shadow">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <span
                        className="text-inherit avatar avatar-lg avatar-4x3"
                        style={{minHeight: "5rem", fontSize: "5vh"}}
                        data-hs-theme-appearance="default"
                    >
                      90
                    </span>
                  </div>

                  <div className="flex-grow-1 ms-4">
                    <h3 className="text-inherit mb-1 text-center"></h3>
                    <span className="text-body">Commandes Livrées</span>
                  </div>

                  <div className="ms-2 text-end">
                    <i className="d-none bi-chevron-right text-body text-inherit"></i>
                  </div>
                </div>
              </div>
            </span>
                    </div>
                </div>
                {/* <!-- End Card --> */}

                {/* <!-- Card --> */}
                <div className="col-lg-3 mb-3 mb-lg-5">
                    <div className="d-grid gap-2 gap-lg-4">
            <span className="card card-hover-shadow">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <span
                        className="text-inherit avatar avatar-lg avatar-4x3"
                        style={{minHeight: "5rem", fontSize: "5vh"}}
                        data-hs-theme-appearance="default"
                    >
                      5
                    </span>
                  </div>

                  <div className="flex-grow-1 ms-4">
                    <h3 className="text-inherit mb-1 text-center"></h3>
                    <span className="text-body">Commandes En Attente</span>
                  </div>

                  <div className="ms-2 text-end">
                    <i className="d-none bi-chevron-right text-body text-inherit"></i>
                  </div>
                </div>
              </div>
            </span>
                    </div>
                </div>
                {/* <!-- End Card --> */}

                {/* <!-- Card --> */}
                <div className="col-lg-3 mb-3 mb-lg-5">
                    <div className="d-grid gap-2 gap-lg-4">
            <span className="card card-hover-shadow">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <span
                        className="text-inherit avatar avatar-lg avatar-4x3"
                        style={{minHeight: "5rem", fontSize: "5vh"}}
                        data-hs-theme-appearance="default"
                    >
                      4
                    </span>
                  </div>

                  <div className="flex-grow-1 ms-4">
                    <h3 className="text-inherit mb-1 text-center"></h3>
                    <span className="text-body">Commandes Anullées</span>
                  </div>

                  <div className="ms-2 text-end">
                    <i className="d-none bi-chevron-right text-body text-inherit"></i>
                  </div>
                </div>
              </div>
            </span>
                    </div>
                </div>
                {/* <!-- End Card --> */}
            </div>

            <div className="container-fluid mt-5 mb-5"></div>

            <div className="container-fluid d-flex justify-content-end bd-highlight"></div>
            {["delivery-company-admin"].some((role) =>
                currentRolesNames?.includes(role)
            ) && (
                <DeliveryCompanyOrderList
                    restaurantId={currentStaff.delivery_company_id}
                />
            )}

            {["restaurant-admin"].some((role) =>
                currentRolesNames?.includes(role)
            ) && <RestaurantOrderList restaurantId={restaurantId}/>}

            {["admin"].some((role) => currentRolesNames?.includes(role)) && (
                <AdminOrderList/>
            )}
        </>
    );
}

export default Order;
