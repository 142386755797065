import React, {useEffect, useState} from "react";
import service from "../../app/api/FetchInterceptor";
import Title from "../../components/Shared/Title";
import DataTable from "../../features/dataTable/DataTableBase";
import Withdraw from "./withdraw";

const Withdraws = ({ }) => {
    const [withdraws, setWithdraws] = useState([])

    const [baseUrl, setBaseUrl] = useState("withdrawals");
    const [url, setUrl] = useState(baseUrl);

    const [meta, setMeta] = useState({})

    const columns = [
        {
            name: "Référence",
            selector: "reference",
            sortable: true,
        },

        {
            name: "Montant",
            selector: "amount",
        },

        {
            name: "Ancien dolde",
            selector: "old_balance",
            type: "price",
        },

        {
            name: "Nouveau solde",
            selector: "new_balance",
            type: "price",
        },

        {
            name: "Date de la transaction",
            selector: "created_at",
            type: "date",
        }
    ];

    useEffect(() => {
        loadWithdraws(url)
    }, [url]);

    const loadWithdraws = (url = null) => {
        service({
            method: "GET",
            url: url ?? baseUrl
        }).then(({ data, current_page, from, last_page, per_page, total }) => {
            setWithdraws(data)

            const meta = {
                currentPage: current_page,
                from: from,
                lastPage: last_page,
                perPage: per_page,
                total: total,
            };

            setMeta(meta)
        })
    }

    return (
        <>
            <Title title="Retraits" />

            <Withdraw loadWithdraws={loadWithdraws} />

            <div className="container mt-4">
                <DataTable
                    columns={columns}
                    data={withdraws}
                    baseUrl={baseUrl}
                    setUrl={setUrl}
                    meta={meta}
                />
            </div>
        </>
    )
}

export default Withdraws;