import React, {useEffect, useState} from "react";
import Title from "../../components/Shared/Title";
import Geolocation from "../../components/Shared/Geolocation";
import {
    useAddRestaurantMutation,
    useGetCreateRestaurantDataQuery,
    useGetRestaurantsQuery,
} from "./restaurantsApiSlice";
import {useDispatch} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {nanoid} from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import Input from "../../components/formik/Input";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import EditableAvailabilityTable from "./EditableAvailabilityTable";
import Select from "react-select";

function CreateRestaurant() {
    const [canUseCurrentLocation, setCanUseCurrentLocation] = useState(false);

    const [telephones, setTelephones] = useState([{key: nanoid(), value: ""}]);

    const [longitude, setLongitude] = useState("");
    const [latitude, setLatitude] = useState("");

    const [days, setDays] = useState([]);

    const [availability, setAvailability] = useState([]);
    const [logo, setLogo] = useState(null);
    const [banner, setBanner] = useState(null);
    const [categoryOptions, setCategoryOptions] = useState(null);
    const [category, setCategory] = useState(null);
    const [leaderPhone, setLeaderPhone] = useState("");
    const [withdrawNumber, setWithdrawNumber] = useState("");
    const [preferManualCoords, setPreferManualCoords] = useState(false);

    const dispatch = useDispatch();
    const [addRestaurant] = useAddRestaurantMutation();
    const navigate = useNavigate();

    const createRequest = useGetCreateRestaurantDataQuery();
    const {refetch} = useGetRestaurantsQuery();

    useEffect(() => {
        if (!canUseCurrentLocation) {
            setLongitude("");
            setLatitude("");
        }
    }, [canUseCurrentLocation]);

    useEffect(() => {
        if (createRequest.isSuccess) {
            const {days, categories} = createRequest.data;

            let formattedCategoryOptions = categories.map((element) => ({
                label: element.name,
                value: element.id,
            }));
            setCategoryOptions(formattedCategoryOptions);

            let formatedDays = days.map((element) => ({
                id: element.id,
                name: element.name,
                number: element.number,
                open_hour: "00",
                open_min: "00",
                close_hour: "23",
                close_min: "59",
                is_available: true,
            }));
            formatedDays.sort((a, b) => (a.number > b.number ? 1 : -1));
            setDays(formatedDays);
        }
    }, [createRequest.isLoading, createRequest.isSuccess]);

    const handleSubmit = (data) => {
        data.telephones = telephones.map((telephone) => telephone.value);

        data.days = availability.map((item) => ({
            id: item.id,
            is_available: item.is_available,
            open_at: item.open_hour + ":" + item.open_min,
            close_at: item.close_hour + ":" + item.close_min,
        }));

        // choose manual or auto coords
        if (preferManualCoords) {
            data.longitude = parseFloat(data.userLongitude.replace(",", "."));
            data.latitude = parseFloat(data.userLatitude.replace(",", "."));
        } else {
            data.longitude = longitude;
            data.latitude = latitude;
        }

        data.logo = logo;
        data.banner = banner;
        data.category = category.value;
        data.telephone = leaderPhone;
        data.withdrawal_number = withdrawNumber;

        if (
            longitude === "" ||
            latitude === "" ||
            typeof longitude === "undefined" ||
            typeof latitude === "undefined"
        ) {
            Swal.fire({
                title: "Erreur",
                text: `Veuillez préciser la longitude et la latitude`,
                icon: "error",
                toast: true,
                position: "top-end",
                timer: 3000,
                timerProgressBar: true,
            });
            return;
        }

        addRestaurant(data)
            .unwrap()
            .then(() => {
                refetch();
                Swal.fire({
                    title: "Succees",
                    text: `Restaurant créé avec succès`,
                    icon: "success",
                    toast: true,
                    position: "top-end",
                    timer: 3000,
                    timerProgressBar: true,
                });
                navigate("/restaurant");
            })
            .catch((response) => {
                Swal.fire({
                    title: "Erreur",
                    text: `Erreur lors de la création du restaurant`,
                    icon: "error",
                    toast: true,
                    position: "top-end",
                    timer: 3000,
                    timerProgressBar: true,
                });
            });
    };

    const onTelephoneChange = ({key, value}) => {
        let telephonesCopy = [...telephones];
        const index = telephonesCopy.findIndex(
            (telephone) => telephone.key === key
        );
        telephonesCopy[index].value = value;
        setTelephones(telephonesCopy);
    };

    const addTelephone = () => {
        const telephonesCopy = [...telephones];
        telephonesCopy.push({key: nanoid(), value: ""});
        setTelephones(telephonesCopy);
    };

    const onTelephoneDelete = ({key}) => {
        if (telephones.length <= 1) {
            setTelephones([{key: nanoid(), value: ""}]);
            return;
        }
        let telephonesCopy = [...telephones];
        const index = telephonesCopy.findIndex(
            (telephone) => telephone.key === key
        );
        telephonesCopy.splice(index, 1);
        setTelephones(telephonesCopy);
    };

    const handleCoords = (coords) => {
        setLongitude(coords?.longitude);
        setLatitude(coords?.latitude);
    };

    const handlePreferManualCoordsChange = () => {
        setPreferManualCoords(!preferManualCoords);
    };

    return (
        <div>
            <Title backPath="/restaurant" title="Nouveau restaurant"/>
            {/* <!-- Body --> */}
            <div className="container-fluid">
                <Formik
                    enableReinitialize
                    initialValues={{
                        name: "",
                        address: "",
                        lastname: "",
                        firstname: "",
                        email: "",
                        password: "",
                        userLongitude: "",
                        userLatitude: "",
                    }}
                    validationSchema={Yup.object({
                        name: Yup.string().required("Obligatoire"),
                        address: Yup.string().required("Obligatoire"),
                        lastname: Yup.string().required("Obligatoire"),
                        firstname: Yup.string().required("Obligatoire"),
                        email: Yup.string().required("Obligatoire"),
                        password: Yup.string().required("Obligatoire"),
                    })}
                    onSubmit={(values, {setSubmitting, resetForm}) => {
                        handleSubmit({...values});
                        setSubmitting(false);
                        resetForm();
                    }}
                >
                    <Form className="row">
                        <div className="col-md-3">
                            <p className="fw-bold text-center">Informations du restaurant</p>
                            <div className="row">
                                <div className="col-md-12 mt-3">
                                    <Input
                                        label="Nom"
                                        name="name"
                                        type="text"
                                        placeholder="Nom"
                                        className="form-control"
                                    />
                                </div>

                                <div className={`col-md-12 mt-3`}>
                                    <p className="fw-bold">Catégorie</p>
                                    <Select
                                        name="category"
                                        options={categoryOptions}
                                        isClearable
                                        isSearchable
                                        onChange={setCategory}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mt-3">
                                    <Input
                                        label="Adresse"
                                        name="address"
                                        type="text"
                                        placeholder="Adresse"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                {telephones.map((telephone, index) => (
                                    <div className="col-md-12 mt-3 d-flex justify-content-between">
                                        <div className="d-flex flex-column">
                                            <label>Téléphone</label>
                                            <PhoneInput
                                                country={"bj"}
                                                enableSearch={false}
                                                onlyCountries={["bj"]}
                                                masks={{bj: "..  ..  ..  .. .."}}
                                                disableCountryCode={true}
                                                disableDropdown={true}
                                                value={telephone.value}
                                                onChange={(value) =>
                                                    onTelephoneChange({
                                                        key: telephone.key,
                                                        value,
                                                    })
                                                }
                                                inputClass="w-100 p-3"
                                                buttonClass="d-none"
                                                containerClass=""
                                                placeholder="01 97  00  00  00"
                                            />
                                        </div>
                                        <div>
                                            <button
                                                onClick={() =>
                                                    onTelephoneDelete({key: telephone.key})
                                                }
                                                className="btn btn-lg btn-ghost-secondary btn-icon rounded-circle"
                                                type="button"
                                            >
                                                <i className="bi bi-trash"></i>
                                            </button>
                                        </div>
                                    </div>
                                ))}
                                <div className="col-md-12 mt-3">
                                    <button
                                        className="btn btn-lg btn-ghost-secondary btn-icon rounded-circle"
                                        type="button"
                                        onClick={addTelephone}
                                    >
                                        <i className="bi bi-plus"></i>
                                    </button>
                                    Ajouter numéro
                                </div>
                            </div>

                            <div className="col-md-12 mt-3">
                                <div className="d-flex flex-column">
                                    <label>Numéro de retrait</label>
                                    <PhoneInput
                                        country={"bj"}
                                        enableSearch={false}
                                        onlyCountries={["bj"]}
                                        masks={{bj: "..  ..  ..  .. .."}}
                                        disableCountryCode={true}
                                        disableDropdown={true}
                                        value={withdrawNumber}
                                        onChange={setWithdrawNumber}
                                        inputClass="w-100 p-3"
                                        buttonClass="d-none"
                                        containerClass=""
                                        placeholder="01 97  00  00  00"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-9">
                            <p className="fw-bold text-center">Disponibilité</p>
                            <EditableAvailabilityTable
                                initialDays={days}
                                setAvailability={setAvailability}
                                canEdit={true}
                            />
                        </div>

                        <div className="row">
                            <div className="col-md-4 mt-3">
                                <input
                                    type="checkbox"
                                    id="preferManualCoords"
                                    checked={preferManualCoords}
                                    onChange={handlePreferManualCoordsChange}
                                />
                                <label htmlFor="preferManualCoords">
                                    Définir manuellement les coordonnées
                                </label>
                            </div>

                            {preferManualCoords ? (
                                <>
                                    <div className="col-md-4 mt-3">
                                        <Input
                                            label="Longitude"
                                            name="userLongitude"
                                            type="text"
                                            placeholder="Longitude"
                                            className="form-control"
                                        />
                                    </div>

                                    <div className="col-md-4 mt-3">
                                        <Input
                                            label="Latitude"
                                            name="userLatitude"
                                            type="text"
                                            placeholder="Latitude"
                                            className="form-control"
                                        />
                                    </div>
                                </>
                            ) : (
                                <Geolocation handleCoords={handleCoords}/>
                            )}
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <label className="form-label">
                                    Logo {logo?.name ? ` : ${logo?.name}` : ""}
                                </label>

                                <input
                                    type="file"
                                    value=""
                                    onChange={(e) => {
                                        setLogo(e.target.files[0]);
                                    }}
                                    className="form-control"
                                />
                            </div>
                            <div className="col-md-6">
                                <label className="form-label">
                                    Bannière {banner?.name ? ` : ${banner?.name}` : ""}
                                </label>

                                <input
                                    type="file"
                                    value=""
                                    onChange={(e) => {
                                        setBanner(e.target.files[0]);
                                    }}
                                    className="form-control"
                                />
                            </div>
                        </div>

                        <div className="container">
                            <h3 className="text-center mt-5">Informations du dirigeant</h3>
                            <div className="row">
                                <div className="col-md-4 mt-3">
                                    <Input
                                        label="Nom du dirigeant"
                                        name="lastname"
                                        type="text"
                                        placeholder="Nom du dirigeant"
                                        className="form-control"
                                    />
                                </div>

                                <div className="col-md-4 mt-3">
                                    <Input
                                        label="Prénom du dirigeant"
                                        name="firstname"
                                        type="text"
                                        placeholder="Prénom du dirigeant"
                                        className="form-control"
                                    />
                                </div>

                                {/* phone */}
                                <div className="col-md-4 mt-3 d-flex justify-content-between">
                                    <div className="d-flex flex-column">
                                        <label>Téléphone</label>
                                        <PhoneInput
                                            country={"bj"}
                                            enableSearch={false}
                                            onlyCountries={["bj"]}
                                            masks={{bj: "..  ..  ..  .. .."}}
                                            disableCountryCode={true}
                                            disableDropdown={true}
                                            value={leaderPhone}
                                            onChange={setLeaderPhone}
                                            inputClass="w-100 p-3"
                                            buttonClass="d-none"
                                            containerClass=""
                                            placeholder="01 97  00  00  00"
                                        />
                                    </div>
                                </div>

                                <div className="mt-3 col-md-4">
                                    <Input
                                        label="Email"
                                        name="email"
                                        type="email"
                                        placeholder=""
                                        className="form-control"
                                    />
                                </div>

                                <div className="mt-3 col-md-4">
                                    <Input
                                        label="Mot de passe"
                                        name="password"
                                        type="password"
                                        placeholder=""
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="mt-5 d-flex bd-highlight justify-content-start">
                                <div className="mr-2">
                                    <button className="btn btn-primary" type="submit">
                                        Créer
                                    </button>
                                </div>

                                <div className="mx-2">
                                    <Link to="/restaurant">
                                        <button className="btn btn-white" type="button">
                                            Annuler
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Form>
                </Formik>
            </div>
        </div>
    );
}

export default CreateRestaurant;
