import {apiSlice} from "../../app/api/apiSlice";

const deliveryPersonApiSlice = apiSlice.injectEndpoints({
    tagTypes: ["DeliveryPersons"],
    endpoints: (builder) => ({
        getAllDeliveryPersons: builder.query({
            query: ({url}) => url,
            providesTags: ["DeliveryPersons"],
        }),
        getDeliveryPerson: builder.query({
            query: ({deliveryPersonId}) => {
                return `deliverers/${deliveryPersonId}`;
            },
        }),
        getCreateDeliveryPersonData: builder.query({
            query: () => "deliverers/create",
        }),
        addDeliveryPerson: builder.mutation({
            query: (deliveryPerson) => ({
                url: "deliverers",
                method: "POST",
                body: {...deliveryPerson},
                redirect: "error",
            }),
            invalidatesTags: ["DeliveryPersons"],
        }),
        updateDeliveryPerson: builder.mutation({
            query: (deliveryPerson) => ({
                url: `deliverers/${deliveryPerson.id}`,
                method: "PUT",
                body: deliveryPerson,
            }),
            invalidatesTags: ["DeliveryPersons"],
        }),
        deleteDeliveryPerson: builder.mutation({
            query: (deliveryPersonId) => ({
                url: `deliverers/${deliveryPersonId}`,
                method: "DELETE",
            }),
            invalidatesTags: ["DeliveryPersons"],
        }),
    }),
});

export const {
    useGetAllDeliveryPersonsQuery,
    useGetDeliveryPersonQuery,
    useGetCreateDeliveryPersonDataQuery,
    useAddDeliveryPersonMutation,
    useUpdateDeliveryPersonMutation,
    useDeleteDeliveryPersonMutation,
} = deliveryPersonApiSlice;
