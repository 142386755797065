export const VALUE_TYPE = {
    amount: "Montant",
    percentage: "Pourcentage",
};

export const CALCULATION_BASIS = {
    amount: "Montant des repas",
    delivery: "Livraison",
};

export const APPLICATE = {
    all: "Tous",
    clients: "Clients spécifiques",
    restaurants: "Restaurants spécifiques",
    meals: "Repas spécifiques",
};

export const PENDING = "pending";

export const ACCEPTED = "accepted";

export const PROCESSING = "processing";
export const ACCEPTED_DELIVERY = "accepted_delivery";
