import React, {useState} from "react";
import DataTable from "../dataTable/DataTableBase";
import {Link} from "react-router-dom";
import STATUS from "../../components/Shared/Status";
import {useGetTransactionsQuery} from "./transactionsApiSlice";

function TransactionList() {
    const [baseUrl, setBaseUrl] = useState("admin/transactions");
    const [url, setUrl] = useState(baseUrl);

    const {
        data: response,
        isLoading,
        isSuccess,
        isError,
        error,
    } = useGetTransactionsQuery({url});

    const columns = [
        {
            name: "Transaction",
            selector: "reference",
        },
        {
            name: "Canal",
            selector: "canal",
        },
        {
            name: "Type",
            selector: "type",
        },
        {
            name: "Montant",
            selector: "amount",
            type: "price",
        },

        {
            name: "Montant Total",
            selector: "total_amount",
            type: "price",
        },

        {
            name: "Frais",
            selector: "fees",
            type: "price",
        },
        {
            name: "Statut",
            selector: "status",
        },
        {
            name: "Date",
            selector: "created_at",
            type: "date",
        },
    ];

    let data = [];
    let meta = {};

    const TYPES = {
        wallet: "Électronique",
        Cash: "En Èspèce",
        cash: "En Èspèce",
        "Ntech\\MainService\\Models\\Recharge": "Recharge",
        "Ntech\\MainService\\Models\\Order": "Commande",
        "Ntech\\MainService\\Models\\Withdrawal": "Retrait",
        "App\\Models\\WalletExchange": "Transfert",
    };

    let content = "";
    if (isLoading) {
        content = <p>Loading ...</p>;
    } else if (isSuccess) {
        data = response.data.map((transaction) => ({
            id: transaction.id,
            status: STATUS[transaction.payment_status],
            amount: transaction.amount,
            fees: transaction.fees,
            reference: transaction.reference,
            created_at: transaction.created_at,
            canal: TYPES[transaction.type],
            type: TYPES[transaction.model_type],
            total_amount: transaction.total_amount,
        }));

        meta = {
            currentPage: response.current_page,
            from: response.from,
            lastPage: response.last_page,
            perPage: response.per_page,
            total: response.total,
        };
    } else if (error) {
        content = <p>{JSON.stringify(error)}</p>;
    }

    const actions = (recharge) => {
        return (
            <div className="fw-bold d-flex">
                <Link to={`/recharge/${recharge.id}`}>
                    <button
                        className="btn btn-lg btn-ghost-secondary btn-icon rounded-circle"
                        type="button"
                    >
                        <i className="bi bi-eye"></i>
                    </button>
                </Link>
            </div>
        );
    };

    return (
        <div className="container">
            <DataTable
                columns={columns}
                data={data}
                actions={false}
                baseUrl={baseUrl}
                setUrl={setUrl}
                meta={meta}
            />
        </div>
    );
}

export default TransactionList;
