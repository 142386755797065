import React, {useEffect, useState} from "react";
import Title from "../../components/Shared/Title";
import {Link, useNavigate, useParams} from "react-router-dom";
import Swal from "sweetalert2";
import Select from "react-select";
import {
    useGetCreatePromotionDataQuery,
    useGetPromotionQuery,
    useUpdatePromotionMutation,
} from "./promotionsApiSlice";
import * as yup from "yup";
import {Controller, useForm, useWatch} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";

function CreatePromotion() {
    const [ updatePromotion ] = useUpdatePromotionMutation();
    const { promotionId} = useParams();

    const navigate = useNavigate()

    const [valueTypeOptions, setValueTypeOptions] = useState(null);
    const [calculationBasisOptions, setCalculationBasisOptions] = useState(null);
    const [mealOptions, setMealOptions] = useState(null);
    const [restaurantsOptions, setRestaurantsOptions] = useState([])
    const [clientsOptions, setClientsOptions] = useState([])
    const [initiatorOptions, setInitiatorOptions] = useState([])

    const createRequest = useGetCreatePromotionDataQuery();

    const applicationOptions = [
        {label: "Tout le monde", value: "all"},
        {label: "Client(s)", value: "clients"},
        {label: "Restaurant(s)", value: "restaurants"},
        {label: "Repas", value: "meals"},
    ]

    const {
        data: response,
        isLoading,
        isSuccess,
        refetch,
    } = useGetPromotionQuery({ promotionId });

    useEffect(() => {
        refetch();

        if (isSuccess) {
            reset({
                name: response.name,
                code: response.code,
                maximum_beneficiaries: response.maximum_beneficiaries,
                max_usage: response.max_usage,
                value_type	: response.value_type,
                value: response.value,
                calculation_basis: response.calculation_basis,
                applicate: response.applicate,
                initiator_type: response.initiator_type,
                restaurants: response.restaurants.map((restaurant) => restaurant.id),
                beneficiaries: response.clients.map((client) => client.id),
                meals: response.meals.map((meal) => meal.id),
            })
        }
    }, [isLoading, isSuccess]);

    useEffect(() => {
        if (createRequest.isSuccess) {
            const {cible, restaurants, clients, value_type, meals, initiators} = createRequest.data;

            setCalculationBasisOptions(cible);

            setClientsOptions(clients.map((client) => ({
                label: client.identity.fullName,
                value: client.id,
            })))

            setRestaurantsOptions(restaurants.map((restaurant) => ({
                label: restaurant.name,
                value: restaurant.id,
            })))

            setValueTypeOptions(value_type);

            setMealOptions(meals.map((meal) => ({
                label: meal.name,
                value: meal.id,
            })));


            setInitiatorOptions(initiators)
        }
    }, [createRequest.isLoading, createRequest.isSuccess]);

    const schema = yup.object().shape({
        code: yup
            .string()
            .required('Ce champ est obligatoire'),

        name: yup
            .string()
            .required('Ce champ est obligatoire'),

        maximum_beneficiaries: yup
            .string()
            .required('Ce champ est obligatoire'),

        max_usage: yup
            .string()
            .required('Ce champ est obligatoire'),

        calculation_basis: yup
            .string()
            .required('Ce champ est obligatoire'),

        value_type: yup
            .string()
            .required('Ce champ est obligatoire'),

        value: yup
            .string()
            .required('Ce champ est obligatoire'),

        applicate: yup
            .string()
            .required('Ce champ est obligatoire'),

        initiator_type: yup
            .string()
            .required('Ce champ est obligatoire'),

        restaurants: yup
            .array(),

        meals: yup
            .array(),

        beneficiaries: yup
            .array(),
    })

    const defaultValues = {}

    const {control, formState, handleSubmit, watch, reset, getValues} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const {errors, isSubmitting} = formState;

    const applicateValue = useWatch({
        control,
        name: "applicate",
    });

    const onSubmit = (values) => {
        updatePromotion({
            id: promotionId,
            ...values,
            _method: "put",
        })
            .unwrap()
            .then(() => {
                Swal.fire({
                    title: "Succees",
                    text: `Code promo créé avec succès`,
                    icon: "success",
                    toast: true,
                    position: "top-end",
                    timer: 3000,
                    timerProgressBar: true,
                });

                navigate("/promotion");
            })
            .catch((response) => {
                Swal.fire({
                    title: "Erreur",
                    text: response.message,
                    icon: "error",
                    toast: true,
                    position: "top-end",
                    timer: 3000,
                    timerProgressBar: true,
                });
            });
    }

    return (
        <div>
            <Title backPath="/promotion" title="Nouveau code promo"/>
            <div className="container-fluid">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="card">
                        <h5 className="card-header">Informations générales</h5>
                        <div className="card-body">
                            <div className="row g-3">
                                <div className="col-md-3">
                                    <Controller
                                        name="code"
                                        control={control}
                                        render={({field}) => (
                                            <>
                                                <label
                                                    className="mb-2 block font-medium text-black dark:text-white required"
                                                    htmlFor="code">
                                                    Code
                                                </label>
                                                <input id="code" {...field} className="form-control"/>
                                                <span style={{color: 'red'}}
                                                      className='error-ref-facture'>{errors?.code?.message}</span>
                                            </>
                                        )}
                                    />
                                </div>

                                <div className="col-md-3">
                                    <Controller
                                        name="name"
                                        control={control}
                                        render={({field}) => (
                                            <>
                                                <label
                                                    className="mb-2 block font-medium text-black dark:text-white required"
                                                    htmlFor="name">
                                                    Nom
                                                </label>
                                                <input id="name" {...field} className="form-control"/>
                                                <span style={{color: 'red'}}
                                                      className='error-ref-facture'>{errors?.name?.message}</span>
                                            </>
                                        )}
                                    />
                                </div>

                                <div className="col-md-3">
                                    <Controller
                                        name="maximum_beneficiaries"
                                        control={control}
                                        render={({field}) => (
                                            <>
                                                <label
                                                    className="mb-2 block font-medium text-black dark:text-white required"
                                                    htmlFor="maximum_beneficiaries">
                                                    Maximum bénéficiaires
                                                </label>
                                                <input {...field} id="maximum_beneficiaries" className="form-control"/>
                                                <span style={{color: 'red'}}
                                                      className='error-ref-facture'>{errors?.maximum_beneficiaries?.message}</span>
                                            </>
                                        )}
                                    />
                                </div>

                                <div className="col-md-3">
                                    <Controller
                                        name="max_usage"
                                        control={control}
                                        render={({field}) => (
                                            <>
                                                <label
                                                    className="mb-2 block font-medium text-black dark:text-white required"
                                                    htmlFor="max_usage">
                                                    Nombre maximum d'usages
                                                </label>
                                                <input {...field} id="maximum_beneficiaries" className="form-control"/>
                                                <span style={{color: 'red'}}
                                                      className='error-ref-facture'>{errors?.max_usage?.message}</span>
                                            </>
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card mt-3">
                        <h5 className="card-header">Valeur</h5>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-3">
                                    <Controller
                                        name="calculation_basis"
                                        control={control}
                                        render={({field}) => (
                                            <>
                                                <label
                                                    className="mb-2 block font-medium text-black dark:text-white required"
                                                    htmlFor="calculation_basis">
                                                    Base de calcul
                                                </label>
                                                <Select
                                                    isClearable={true}
                                                    placeholder="Base de calcul"
                                                    {...field}
                                                    options={calculationBasisOptions}
                                                    onChange={(selectedOption) => field.onChange(selectedOption ? selectedOption.value : '')}
                                                    defaultValue={calculationBasisOptions?.find(option => option.value === field.value)}
                                                    value={calculationBasisOptions?.find(option => option.value === field.value)}
                                                />
                                                <span style={{color: 'red'}}
                                                      className='error-ref-facture'>{errors?.calculation_basis?.message}</span>
                                            </>
                                        )}
                                    />
                                </div>

                                <div className="col-md-3">
                                    <Controller
                                        name="value_type"
                                        control={control}
                                        render={({field}) => (
                                            <>
                                                <label
                                                    className="mb-2 block font-medium text-black dark:text-white required"
                                                    htmlFor="value_type">
                                                    Type de valeur
                                                </label>
                                                <Select
                                                    isClearable={true}
                                                    placeholder="Type de valeur"
                                                    {...field}
                                                    options={valueTypeOptions}
                                                    onChange={(selectedOption) => field.onChange(selectedOption ? selectedOption.value : '')}
                                                    defaultValue={valueTypeOptions?.find(option => option.value === field.value)}
                                                    value={valueTypeOptions?.find(option => option.value === field.value)}
                                                />
                                                <span style={{color: 'red'}}
                                                      className='error-ref-facture'>{errors?.value_type?.message}</span>
                                            </>
                                        )}
                                    />
                                </div>

                                <div className="col-md-3">
                                    <Controller
                                        name="value"
                                        control={control}
                                        render={({field}) => (
                                            <>
                                                <label
                                                    className="mb-2 block font-medium text-black dark:text-white required"
                                                    htmlFor="value">
                                                    Valeur
                                                </label>
                                                <input {...field} id="value" className="form-control"/>
                                                <span style={{color: 'red'}}
                                                      className='error-ref-facture'>{errors?.value?.message}</span>
                                            </>
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card mt-3">
                        <h5 className="card-header">Application</h5>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-3">
                                    <Controller
                                        name="initiator_type"
                                        control={control}
                                        render={({field}) => (
                                            <>
                                                <label
                                                    className="mb-2 block font-medium text-black dark:text-white required"
                                                    htmlFor="initiator_type">
                                                    Initiateur
                                                </label>
                                                <Select
                                                    isClearable={true}
                                                    placeholder="Initiateur"
                                                    {...field}
                                                    options={initiatorOptions}
                                                    onChange={(selectedOption) => field.onChange(selectedOption ? selectedOption.value : '')}
                                                    defaultValue={initiatorOptions.find(option => option.value === field.value)}
                                                    value={initiatorOptions.find(option => option.value === field.value)}
                                                />
                                                <span style={{color: 'red'}}
                                                      className='error-ref-facture'>{errors?.initiator_type?.message}</span>
                                            </>
                                        )}
                                    />
                                </div>

                                <div className="col-md-3">
                                    <Controller
                                        name="applicate"
                                        control={control}
                                        render={({field}) => (
                                            <>
                                                <label
                                                    className="mb-2 block font-medium text-black dark:text-white required"
                                                    htmlFor="applicate">
                                                    Applique à
                                                </label>
                                                <Select
                                                    isClearable={true}
                                                    placeholder="Applique à"
                                                    {...field}
                                                    options={applicationOptions}
                                                    onChange={(selectedOption) => field.onChange(selectedOption ? selectedOption.value : '')}
                                                    defaultValue={applicationOptions.find(option => option.value === field.value)}
                                                    value={applicationOptions.find(option => option.value === field.value)}
                                                />
                                                <span style={{color: 'red'}}
                                                      className='error-ref-facture'>{errors?.applicate?.message}</span>
                                            </>
                                        )}
                                    />
                                </div>

                                {
                                    applicateValue === "restaurants" && (
                                        <div className="col-md-3">
                                            <Controller
                                                name="restaurants"
                                                control={control}
                                                render={({field}) => (
                                                    <>
                                                        <label
                                                            className="mb-2 block font-medium text-black dark:text-white required"
                                                            htmlFor="restaurants">
                                                            Restaurant
                                                        </label>
                                                        <Select
                                                            isMulti={true}
                                                            isClearable={true}
                                                            placeholder="Restaurant (s)"
                                                            {...field}
                                                            options={restaurantsOptions}
                                                            onChange={(selectedOptions) => {
                                                                field.onChange(selectedOptions ? selectedOptions.map(option => option.value) : []);
                                                            }}
                                                            value={restaurantsOptions
                                                                .filter(option => field.value?.includes(option.value))
                                                            }
                                                        />
                                                        <span style={{color: 'red'}}
                                                              className='error-ref-facture'>{errors?.restaurants?.message}</span>
                                                    </>
                                                )}
                                            />
                                        </div>
                                    )
                                }

                                {
                                    applicateValue === "meals" && (
                                        <div className="col-md-3">
                                            <Controller
                                                name="meals"
                                                control={control}
                                                render={({field}) => (
                                                    <>
                                                        <label
                                                            className="mb-2 block font-medium text-black dark:text-white required"
                                                            htmlFor="meals">
                                                            Repas
                                                        </label>
                                                        <Select
                                                            isMulti={true}
                                                            isClearable={true}
                                                            placeholder="Repas"
                                                            {...field}
                                                            options={mealOptions}
                                                            onChange={(selectedOptions) => {
                                                                field.onChange(selectedOptions ? selectedOptions.map(option => option.value) : []);
                                                            }}
                                                            value={mealOptions
                                                                .filter(option => field.value?.includes(option.value))
                                                            }
                                                        />
                                                        <span style={{color: 'red'}}
                                                              className='error-ref-facture'>{errors?.meals?.message}</span>
                                                    </>
                                                )}
                                            />
                                        </div>
                                    )
                                }

                                {
                                    applicateValue === "clients" && (
                                        <div className="col-md-3">
                                            <Controller
                                                name="beneficiaries"
                                                control={control}
                                                render={({field}) => (
                                                    <>
                                                        <label
                                                            className="mb-2 block font-medium text-black dark:text-white required"
                                                            htmlFor="beneficiaries">
                                                            Client (s)
                                                        </label>
                                                        <Select
                                                            isMulti={true}
                                                            isClearable={true}
                                                            placeholder="Client (s)"
                                                            {...field}
                                                            options={clientsOptions}
                                                            onChange={(selectedOptions) => {
                                                                field.onChange(selectedOptions ? selectedOptions.map(option => option.value) : []);
                                                            }}
                                                            value={clientsOptions
                                                                .filter(option => field.value?.includes(option.value))
                                                            }
                                                        />
                                                        <span style={{color: 'red'}}
                                                              className='error-ref-facture'>{errors?.beneficiaries?.message}</span>
                                                    </>
                                                )}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>

                    <div className="mt-5 d-flex bd-highlight justify-content-start">
                        <div className="mx-2">
                            <button disabled={isSubmitting} className="btn btn-primary" type="submit">
                                Modifier
                            </button>
                        </div>

                        <div className="mx-2">
                            <Link to="/promotion">
                                <button className="btn btn-white" type="button">
                                    Annuler
                                </button>
                            </Link>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default CreatePromotion;
