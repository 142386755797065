import React, {useState} from "react";
import DataTable from "../dataTable/DataTableBase";
import {Link, useNavigate} from "react-router-dom";
import {
    useDeletePromotionMutation,
    useGetPromotionsQuery, useUpdatePromotionMutation,
} from "./promotionsApiSlice";
import Swal from "sweetalert2";

function PromotionList() {
    const [updatePromotion] = useUpdatePromotionMutation();
    const [deletePromotion] = useDeletePromotionMutation();

    const [baseUrl, setBaseUrl] = useState("promo-codes");
    const [url, setUrl] = useState(baseUrl);

    const navigate = useNavigate()

    const {
        data: response,
        isLoading,
        isSuccess,
        isError,
        error,
    } = useGetPromotionsQuery({url});

    const columns = [
        {
            name: "Nom",
            selector: "name",
            sortable: true,
        },
        {
            name: "Statut",
            selector: "status",
            type: "status",
        },
    ];

    let data = [];
    let meta = {};

    let content = "";
    if (isLoading) {
        content = <p>Loading ...</p>;
    } else if (isSuccess) {
        data = response.data.map((promo) => ({
            id: promo.id,
            name: promo.name,
            status: promo.status,
            address: promo.address,
        }));

        meta = {
            currentPage: response.current_page,
            from: response.from,
            lastPage: response.last_page,
            perPage: response.per_page,
            total: response.total,
        };
    } else if (error) {
        content = <p>{JSON.stringify(error)}</p>;
    }

    const handleStatusUpdate = (status, promotionId) => {
        const data = {id: promotionId};

        if (status === "suspend") {
            data.suspend = true;
        } else {
            data.status = status;
        }
        updatePromotion(data)
            .unwrap()
            .then((response) => {
                if (response?.error?.originalStatus === 500) {
                    Swal.fire({
                        title: "Erreur",
                        text: `Impossible de mettre à jour le status de la promotion`,
                        icon: "error",
                        toast: true,
                        position: "top-end",
                        timer: 3000,
                        timerProgressBar: true,
                    });
                } else {
                    Swal.fire({
                        title: "Succees",
                        text: `Status de la promotion mis à jour.`,
                        icon: "success",
                        toast: true,
                        position: "top-end",
                        timer: 3000,
                        timerProgressBar: true,
                    });
                }
            })
            .catch((response) => {
                Swal.fire({
                    title: "Erreur",
                    text: `Impossible de mettre à jour le status de la promotion`,
                    icon: "error",
                    toast: true,
                    position: "top-end",
                    timer: 3000,
                    timerProgressBar: true,
                });
            });
    };


    const updatePromotionStatus = (id) => {
        Swal.fire({
            title: "Mettre à jour le statut de la promotion",
            text: "Choisissez une action à effectuer",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Valider",
            cancelButtonText: "Invalider",
        }).then((result) => {
            if (result.isConfirmed) {
                handleStatusUpdate("valid", id)
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                handleStatusUpdate("invalid", id)
            }
        });
    }

    async function displayConfirmation(promotion) {
        const swal = await Swal.fire({
            title: "Confirmation",
            text: `Voulez-vous vraiment supprimer ${promotion.name} ?`,
            icon: "question",
            confirmButtonText: "Oui",
            denyButtonText: "Non",
            showDenyButton: true,
        });
        const isDeleteConfirmed = swal.isConfirmed;

        if (isDeleteConfirmed) {
            deletePromotion(promotion.id)
                .unwrap()
                .then(() => {
                    // refetch();
                    Swal.fire({
                        title: "Succees",
                        text: `Promotion supprimée avec succès`,
                        icon: "success",
                        toast: true,
                        position: "top-end",
                        timer: 3000,
                        timerProgressBar: true,
                    });
                    navigate("/promotion");
                })
                .catch((error) => {
                    let text = error.data.message;
                    Swal.fire({
                        title: "Erreur",
                        text,
                        icon: "error",
                        toast: true,
                        position: "top-end",
                        timer: 3000,
                        timerProgressBar: true,
                    });
                });
        }
    }

    const actions = (promotion) => {
        return (
            <div className="fw-bold d-flex">
                <Link to={`/promotion/${promotion.id}`}>
                    <button
                        className="btn btn-ghost-secondary btn-icon rounded-circle btn-lg"
                        type="button"
                    >
                        <i className="bi bi-eye fs-3"></i>
                    </button>
                </Link>

                <Link to={`/promotion/edit/${promotion.id}`}>
                    <button
                        className="btn btn-ghost-secondary btn-icon rounded-circle btn-lg"
                        type="button"
                    >
                        <i className="bi bi-pencil fs-3"></i>
                    </button>
                </Link>

                <button
                    className="btn btn-ghost-secondary btn-icon rounded-circle btn-lg"
                    type="button"
                    title="Metrre a jour le statut"
                    onClick={() => updatePromotionStatus(promotion.id)}
                >
                    <i className="bi bi-arrow-down-up fs-3"></i>
                </button>

                <button
                    className="btn btn-ghost-secondary btn-icon rounded-circle btn-lg"
                    type="button"
                    onClick={() => displayConfirmation(promotion)}
                >
                    <i className="bi bi-trash fs-3"></i>
                </button>
            </div>
        );
    };

    return (
        <div className="container">
            <Link className="btn btn-primary mb-2" to='/promotion/create'>Ajouter un code promo</Link>

            <DataTable
                columns={columns}
                data={data}
                actions={actions}
                baseUrl={baseUrl}
                setUrl={setUrl}
                meta={meta}
            />
        </div>
    );
}

export default PromotionList;
