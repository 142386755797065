import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {setCredentials, logOut} from "../../features/auth/authSlice";

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    // credentials: "include",
    prepareHeaders: (headers, {getState}) => {
        const token = getState().auth.token;
        if (token) {
            headers.set("authorization", `Bearer ${token}`);
        }
        return headers;
    },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);

    if (result?.error?.originalStatus === 401) {
        console.log("sending refresh token");
        // send refresh token to get new access token
        const refreshResult = await baseQuery("/refresh", api, extraOptions); //todo refresh endpoint
        console.log(refreshResult);
        if (refreshResult?.data) {
            const user = api.getState().auth.user;
            //   store the new token
            api.dispatch(setCredentials({...refreshResult.data, user}));

            //   retry the original query with access token
            result = await (args, api, extraOptions);
        } else {
            api.dispatch(logOut());
        }
    }

    return result;
};

export const apiSlice = createApi({
    baseQuery: baseQueryWithReauth,
    keepUnusedDataFor: 1,
    endpoints: (builder) => ({}),
});
