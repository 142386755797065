import React, {useState} from "react";
import DataTable from "../dataTable/DataTableBase";
import {useGetDrinksQuery} from "./drinksApiSlice";
import ReadDrinkModal from "../../components/Drink/ReadDrinkModal";
import UpdateDrinkModal from "./UpdateDrinkModal";
import DeleteDrinkModal from "./DeleteDrinkModal";

function DrinkList() {
    const [baseUrl, setBaseUrl] = useState("drinks");
    const [url, setUrl] = useState(baseUrl);

    const {
        data: response,
        isLoading,
        isSuccess,
        isError,
        error,
    } = useGetDrinksQuery({url});

    const columns = [
        {
            name: "",
            selector: "image",
            sortable: true,
            type: "image",
        },
        {
            name: "Nom",
            selector: "name",
            sortable: true,
        },
        {
            name: "Prix",
            selector: "price",
            type: "price",
        },
    ];

    let data = [];
    let meta = {};

    let content = "";
    if (isLoading) {
        content = <p>Loading ...</p>;
    } else if (isSuccess) {
        data = response.data.map((drink) => ({
            id: drink.id,
            name: drink.name,
            price: drink.price,
            restaurant: drink.restaurant,
            category: drink?.category,
            drink_category_id: drink.drink_category_id,
            restaurant_id: drink.restaurant_id,
            image: process.env.PUBLIC_URL + "/assets/svg/logos/logo.png",
        }));

        meta = {
            currentPage: response.current_page,
            from: response.from,
            lastPage: response.last_page,
            perPage: response.per_page,
            total: response.total,
        };
    } else if (error) {
        content = <p>{JSON.stringify(error)}</p>;
    }

    const actions = (drink) => {
        return (
            <div className="fw-bold d-flex">
                <ReadDrinkModal drink={drink}/>
                <UpdateDrinkModal drink={drink}/>
                <DeleteDrinkModal drink={drink}/>
            </div>
        );
    };

    return (
        <div className="container-fluid">
            <DataTable
                columns={columns}
                data={data}
                actions={actions}
                baseUrl={baseUrl}
                setUrl={setUrl}
                meta={meta}
            />
        </div>
    );
}

export default DrinkList;
