import React, {useEffect, useState} from "react";
import Title from "../../components/Shared/Title";
import {Link, useNavigate} from "react-router-dom";
import {nanoid} from "@reduxjs/toolkit";
import {
    useAddMealMutation,
    useGetCreateMealDataQuery,
} from "./mealsApiSlice";
import Select from "react-select";
import {useSelector} from "react-redux";
import {selectRoles} from "../auth/authSlice";
import {selectSelectedRestaurant} from "../restaurants/restaurantSlice";
import * as yup from "yup";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import Swal from "sweetalert2";

function CreateMeal() {
    const [supplements, setSupplements] = useState([]);

    const [restaurantOptions, setRestaurantOptions] = useState(null);
    const [mealCategoryOptions, setMealCategoryOptions] = useState(null);
    const [mealSupplementOptions, setMealSupplementOptions] = useState(null);
    const [mealOptionOptions, setMealOptionOptions] = useState(null);
    const [tagOptions, setTagOptions] = useState(null);

    const currentRoles = useSelector(selectRoles);
    const currentRolesNames = currentRoles?.map((role) => role.name);
    const selectedRestaurant = useSelector(selectSelectedRestaurant);

    const [addMeal] = useAddMealMutation();
    const navigate = useNavigate();

    const createRequest = useGetCreateMealDataQuery();

    const schema = yup.object().shape({
        name: yup
            .string()
            .required('Ce champ est obligatoire'),

        price: yup
            .number()
            .required('Ce champ est obligatoire'),

        meal_category_id: yup
            .string()
            .required('Ce champ est obligatoire'),

        restaurant_id: yup
            .string()
            .default(["restaurant-admin"].some((role) => currentRolesNames?.includes(role)) ? selectedRestaurant?.id : "")
            .required('Ce champ est obligatoire'),

        description: yup
            .string()
            .required('Ce champ est obligatoire'),

        options: yup
            .array(),

        tags: yup
            .array(),

        images: yup
            .array()
            .min(1, 'At least one image is required')  // At least 1 image
            .max(5, 'You can upload a maximum of 5 images')  // No more than 5 images
        // .test('fileType', 'Only images are allowed', (files) =>
        //     files.every(file => file.type.startsWith('image/'))
        // ),
    })

    const defaultValues = {}

    const {control, formState, setError, handleSubmit, watch, reset, getValues} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const {errors, isSubmitting} = formState;

    const onSubmit = async (data) => {
        try {
            await addMeal({
                ...data,
                supplements,
                is_suggested: 0,
            }).unwrap();

            Swal.fire({
                title: "Succès",
                text: `Repas créé avec succès`,
                icon: "success",
                toast: true,
                position: "top-end",
                timer: 3000,
                timerProgressBar: true,
            });

            navigate("/meal");
        } catch (error) {
            Object.entries(error.data.errors).forEach(([field, messages]) => {
                setError(field, {
                    type: "custom",
                    message: messages.join(", "),
                });
            });

            Swal.fire({
                title: "Erreur",
                text: error.message || "Une erreur est survenue",
                icon: "error",
                toast: true,
                position: "top-end",
                timer: 3000,
                timerProgressBar: true,
            });
        }
    };

    useEffect(() => {
        if (createRequest.isSuccess) {
            const {restaurants, categories, supplements, options, tags} = createRequest.data;

            const formattedRestaurants = restaurants.map((restaurant) => ({
                label: restaurant.name,
                value: restaurant.id,
            }));
            setRestaurantOptions(formattedRestaurants);

            const formattedCategories = categories.map((category) => ({
                label: category.name,
                value: category.id,
            }));
            setMealCategoryOptions(formattedCategories);

            const formattedSupplements = supplements.map((supplement) => ({
                label: supplement.name,
                value: supplement.id,
            }));
            setMealSupplementOptions(formattedSupplements);

            const formattedOptions = options.map((option) => ({
                label: option.name,
                value: option.id,
            }));
            setMealOptionOptions(formattedOptions);

            const formattedTagsOptions = tags.map((tag) => ({
                label: tag.name,
                value: tag.id,
            }));
            setTagOptions(formattedTagsOptions);
        }
    }, [createRequest.isLoading, createRequest.isSuccess]);

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //
    //     let files = images.map((image) => image.value);
    //
    //     const options = mealOptions?.map((option) => option.value);
    //     const tagsSelected = tags?.map((tag) => tag.value);
    //
    //     let data = {
    //         name,
    //         description,
    //         price,
    //         meal_category_id: mealCategory.value,
    //         images: files,
    //         supplements,
    //         options,
    //         tags: tagsSelected,
    //     };
    //     data.is_suggested = 0;
    //
    //     if (
    //         ["restaurant-admin"].some((role) => currentRolesNames?.includes(role))
    //     ) {
    //         data.restaurant_id = selectedRestaurant.id;
    //     } else {
    //         data.restaurant_id = restaurant?.value;
    //     }
    //
    //     addMeal(data)
    //         .unwrap()
    //         .then(() => {
    //             Swal.fire({
    //                 title: "Succees",
    //                 text: `Repas créé avec succès`,
    //                 icon: "success",
    //                 toast: true,
    //                 position: "top-end",
    //                 timer: 3000,
    //                 timerProgressBar: true,
    //             });
    //             navigate("/meal");
    //         })
    //         .catch((response) => {
    //             Swal.fire({
    //                 title: "Erreur",
    //                 text: `Erreur lors de la création du repas`,
    //                 icon: "error",
    //                 toast: true,
    //                 position: "top-end",
    //                 timer: 3000,
    //                 timerProgressBar: true,
    //             });
    //         });
    // };


    const addSupplement = () => {
        setSupplements([
            ...supplements,
            {
                key: nanoid(),
                supplement_id: null,
                price: 0
            }
        ]);
    };


    const onSupplementDelete = ({key}) => {
        if (supplements.length <= 1) {
            setSupplements([{key: nanoid(), supplement_id: null, price: 0}]);
            return;
        }
        let supplementsCopy = [...supplements];
        const index = supplementsCopy.findIndex(
            (supplement) => supplement.key === key
        );
        supplementsCopy.splice(index, 1);
        setSupplements(supplementsCopy);
    };

    const handleSupplementChange = (element, key) => {
        let supplementsCopy = [...supplements];
        const index = supplementsCopy.findIndex(
            (supplement) => supplement.key === key
        );
        supplementsCopy[index].supplement_id = element?.value;
        setSupplements(supplementsCopy);
    };

    const handleSupplementPriceChange = (price, key) => {
        let supplementsCopy = [...supplements];
        const index = supplementsCopy.findIndex(
            (supplement) => supplement.key === key
        );
        supplementsCopy[index].price = price;
        setSupplements(supplementsCopy);
    };

    return (
        <div>
            <Title backPath="/meal" title="Nouveau repas"/>
            {/* <!-- Body --> */}
            <div className="container-fluid">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="card">
                        <h5 className="card-header">Informations générales</h5>
                        <div className="card-body">
                            <div className="row g-3">
                                <div className="col-md-3">
                                    <Controller
                                        name="tags"
                                        control={control}
                                        render={({field}) => (
                                            <>
                                                <label
                                                    className="mb-2 block font-medium text-black dark:text-white required"
                                                    htmlFor="tags">
                                                    Images
                                                </label>
                                                <Controller
                                                    name="images"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input
                                                            className="form-control"
                                                            type="file"
                                                            accept="image/*"
                                                            multiple
                                                            onChange={(e) => {
                                                                const files = e.target.files ? Array.from(e.target.files) : [];
                                                                field.onChange(files); // Passer directement les fichiers au champ
                                                            }}
                                                            onBlur={field.onBlur} // Conserver le comportement de React Hook Form pour le blur
                                                        />
                                                    )}
                                                />
                                                <span style={{color: 'red'}}
                                                      className='error-ref-facture'>{errors?.images?.message}</span>
                                            </>
                                        )}
                                    />
                                </div>

                                <div className="col-md-3">
                                    <Controller
                                        name="name"
                                        control={control}
                                        render={({field}) => (
                                            <>
                                                <label
                                                    className="mb-2 block font-medium text-black dark:text-white required"
                                                    htmlFor="name">
                                                    Nom
                                                </label>
                                                <input id="name" {...field} className="form-control"/>
                                                <span style={{color: 'red'}}
                                                      className='error-ref-facture'>{errors?.name?.message}</span>
                                            </>
                                        )}
                                    />
                                </div>

                                <div className="col-md-3">
                                    <Controller
                                        name="price"
                                        control={control}
                                        render={({field}) => (
                                            <>
                                                <label
                                                    className="mb-2 block font-medium text-black dark:text-white required"
                                                    htmlFor="price">
                                                    Prix
                                                </label>
                                                <input id="price" type="number" {...field} className="form-control"/>
                                                <span style={{color: 'red'}}
                                                      className='error-ref-facture'>{errors?.price?.message}</span>
                                            </>
                                        )}
                                    />
                                </div>

                                <div className="col-md-3">
                                    <Controller
                                        name="meal_category_id"
                                        control={control}
                                        render={({field}) => (
                                            <>
                                                <label
                                                    className="mb-2 block font-medium text-black dark:text-white required"
                                                    htmlFor="meal_category_id">
                                                    Catégorie de repas
                                                </label>
                                                <Select
                                                    isClearable={true}
                                                    placeholder="Catégorie de repas"
                                                    {...field}
                                                    options={mealCategoryOptions}
                                                    onChange={(selectedOption) => field.onChange(selectedOption ? selectedOption.value : '')}
                                                    defaultValue={mealCategoryOptions?.find(option => option.value === field.value)}
                                                    value={mealCategoryOptions?.find(option => option.value === field.value)}
                                                />
                                                <span style={{color: 'red'}}
                                                      className='error-ref-facture'>{errors?.meal_category_id?.message}</span>
                                            </>
                                        )}
                                    />
                                </div>

                                {
                                    ["admin"].some((role) =>
                                        currentRolesNames?.includes(role)
                                    ) && (
                                        <div className="col-md-3">
                                            <Controller
                                                name="restaurant_id"
                                                control={control}
                                                render={({field}) => (
                                                    <>
                                                        <label
                                                            className="mb-2 block font-medium text-black dark:text-white required"
                                                            htmlFor="restaurant_id">
                                                            Restaurant
                                                        </label>
                                                        <Select
                                                            isClearable={true}
                                                            placeholder="Restaurant"
                                                            {...field}
                                                            options={restaurantOptions}
                                                            onChange={(selectedOption) => field.onChange(selectedOption ? selectedOption.value : '')}
                                                            defaultValue={restaurantOptions?.find(option => option.value === field.value)}
                                                            value={restaurantOptions?.find(option => option.value === field.value)}
                                                        />
                                                        <input id="restaurant_id" {...field} className="form-control"/>
                                                        <span style={{color: 'red'}}
                                                              className='error-ref-facture'>{errors?.restaurant_id?.message}</span>
                                                    </>
                                                )}
                                            />
                                        </div>
                                    )
                                }

                                <div className="col-lg-12">
                                    <Controller
                                        name="description"
                                        control={control}
                                        render={({field}) => (
                                            <>
                                                <label
                                                    className="mb-2 block font-medium text-black dark:text-white required"
                                                    htmlFor="description">
                                                    Description
                                                </label>
                                                <input id="description" {...field} className="form-control"/>
                                                <span style={{color: 'red'}}
                                                      className='error-ref-facture'>{errors?.description?.message}</span>
                                            </>
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card mt-4">
                        <h5 className="card-header">Options</h5>
                        <div className="card-body">
                            <div className="row g-3">
                                <div className="col-md-3">
                                    <Controller
                                        name="options"
                                        control={control}
                                        render={({field}) => (
                                            <>
                                                <label
                                                    className="mb-2 block font-medium text-black dark:text-white required"
                                                    htmlFor="options">
                                                    Options du repas
                                                </label>
                                                <Select
                                                    isClearable={true}
                                                    placeholder="Options du repas"
                                                    {...field}
                                                    isMulti={true}
                                                    options={mealOptionOptions}
                                                    onChange={(selectedOptions) => {
                                                        field.onChange(selectedOptions ? selectedOptions.map(option => option.value) : []);
                                                    }}
                                                    value={mealOptionOptions?.filter(option => field.value?.includes(option.value))}
                                                />
                                                <span style={{color: 'red'}}
                                                      className='error-ref-facture'>{errors?.options?.message}</span>
                                            </>
                                        )}
                                    />
                                </div>

                                <div className="col-md-3">
                                    <Controller
                                        name="tags"
                                        control={control}
                                        render={({field}) => (
                                            <>
                                                <label
                                                    className="mb-2 block font-medium text-black dark:text-white required"
                                                    htmlFor="tags">
                                                    Tags
                                                </label>
                                                <Select
                                                    isClearable={true}
                                                    placeholder="Tags"
                                                    {...field}
                                                    isMulti={true}
                                                    options={tagOptions}
                                                    onChange={(selectedOptions) => {
                                                        field.onChange(selectedOptions ? selectedOptions.map(option => option.value) : []);
                                                    }}
                                                    value={tagOptions?.filter(option => field.value?.includes(option.value))}
                                                />
                                                <span style={{color: 'red'}}
                                                      className='error-ref-facture'>{errors?.tags?.message}</span>
                                            </>
                                        )}
                                    />
                                </div>
                            </div>
                        </div>

                        <h5 className="card-header">Suppléments</h5>

                        <div className="card-body">
                            <button type="button" className="btn btn-primary" onClick={addSupplement}>Ajouter
                                supplément
                            </button>
                            {supplements.map((supplement, index) => (
                                <>
                                    <h4 className="mt-3">Supplément {index + 1}</h4>

                                    <div className="row g-3">
                                        <div className="col">
                                            <Select
                                                isSearchable
                                                placeholder="Options du repas"
                                                options={mealSupplementOptions}
                                                onChange={(element) =>
                                                    handleSupplementChange(element, supplement.key)
                                                }
                                            />
                                        </div>

                                        <div className="col">
                                            <input
                                                onChange={(e) =>
                                                    handleSupplementPriceChange(
                                                        e.target.value,
                                                        supplement.key
                                                    )
                                                }
                                                type="number"
                                                placeholder="Prix"
                                                className="form-control"
                                            />
                                        </div>

                                        <div className="col">
                                            <button
                                                onClick={() => onSupplementDelete({key: supplement.key})}
                                                className="btn btn-ghost-secondary btn-icon rounded-circle"
                                                type="button"
                                            >
                                                <i className="bi bi-trash"></i>
                                            </button>
                                        </div>
                                    </div>
                                </>
                            ))}
                        </div>
                    </div>

                    <div className="mt-5 d-flex bd-highlight justify-content-start">
                        <div className="mx-2">
                            <button disabled={isSubmitting} className="btn btn-primary" type="submit">
                                Créer
                            </button>
                        </div>

                        <div className="mx-2">
                            <Link to="/meal">
                                <button className="btn btn-white" type="button">
                                    Annuler
                                </button>
                            </Link>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default CreateMeal;
