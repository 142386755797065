import Title from "../components/Shared/Title";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import DrinkList from "../features/drink/DrinkList";
import CreateDrinkModal from "../features/drink/CreateDrinkModal";
import React from "react";
import useRedirectUserIfNoRestaurantSelected from "../hooks/restaurants/useRedirectUserIfNoRestaurantSelected";
import {v4 as uuidv4} from "uuid";
import {useGetDrinksStatsQuery} from "../features/drink/drinksApiSlice";

function Drink() {
    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const [statistics, setStatistics] = useState([
        {quantity: 0, description: "Total des Repas"},
        {quantity: 0, description: "Repas Non Validés"},
        {quantity: 0, description: "Repas Validés"},
        {quantity: 0, description: "Repas en Promo"},
    ]);

    useRedirectUserIfNoRestaurantSelected();

    const statsRequest = useGetDrinksStatsQuery();

    useEffect(() => {
        if (statsRequest.status === "fulfilled") {
            const {total} = statsRequest.data;
            setStatistics([
                {quantity: 0, description: "Boissons Déja Commandées"},
                {quantity: 0, description: "Boissons Jamais Commandées"},
                {quantity: 0, description: "Boissons En Promotion"},
                {quantity: total, description: "Total des Boissons"},
            ]);
        }
    }, [statsRequest.status]);

    return (
        <>
            <Title title="Boissons"/>

            <div className="row d-none">
                {statistics.map((stat) => (
                    <div key={uuidv4()} className="col-lg-3 mb-3 mb-lg-5">
                        {/* <!-- Card --> */}
                        <div className="d-grid gap-2 gap-lg-4">
              <span className="card card-hover-shadow">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <span
                          className="text-inherit avatar avatar-lg avatar-4x3 d-flex"
                          style={{minHeight: "5rem", fontSize: "5vh"}}
                          data-hs-theme-appearance="default"
                      >
                        {stat.quantity}
                      </span>
                    </div>

                    <div className="flex-grow-1 ms-4">
                      <h3 className="text-inherit mb-1 text-center"></h3>
                      <span className="text-body">{stat.description}</span>
                    </div>

                    <div className="ms-2 text-end">
                      <i className="d-none bi-chevron-right text-body text-inherit"></i>
                    </div>
                  </div>
                </div>
              </span>
                        </div>
                        {/* <!-- End Card --> */}
                    </div>
                ))}
            </div>

            <div className="container-fluid mt-5 mb-5">
                <div className="d-none">
                    <button
                        type="button"
                        className="btn btn-white "
                        onClick={() => setIsFilterVisible(!isFilterVisible)}
                    >
                        <i className="bi-filter me-1"></i> Filtres
                    </button>
                </div>

                {isFilterVisible && (
                    <div className="row">
                        <div className="col-md-4 mt-3">
                            <input
                                type="text"
                                className="form-control"
                                id="eventLocationLabel"
                                placeholder="Nom de la catégorie"
                                aria-label="Nom de la catégorie"
                            />
                        </div>

                        <div className="col-md-4 mt-3">
                            <input
                                type="text"
                                className="form-control"
                                id="eventLocationLabel"
                                placeholder="Nom du restaurant"
                                aria-label="Nom du restaurant"
                            />
                        </div>

                        <div className="col-md-4 mt-3">
                            {/* <!-- Select --> */}
                            <div className="tom-select-custom">
                                <select
                                    className="js-select form-select w-full"
                                    autoComplete="off"
                                    id="eventColorLabel"
                                    data-hs-tom-select-options='{
                        "searchInDropdown": false,
                        "placeholder": "Select event color"
                      }'
                                >
                                    <option
                                        value=""
                                        data-option-template='<span className="d-flex align-items-center">Select event color</span>'
                                    >
                                        Volume d'activité
                                    </option>
                                    <option
                                        value="fullcalendar-custom-event-hs-team"
                                        data-option-template='<span className="d-flex align-items-center"><span className="legend-indicator bg-primary me-2"></span>HS Team</span>'
                                    >
                                        Categories Actives
                                    </option>
                                    <option
                                        value="fullcalendar-custom-event-reminders"
                                        data-option-template='<span className="d-flex align-items-center"><span className="legend-indicator bg-danger me-2"></span>Reminders</span>'
                                    >
                                        Categories Vides
                                    </option>
                                    <option
                                        value="fullcalendar-custom-event-tasks"
                                        data-option-template='<span className="d-flex align-items-center"><span className="legend-indicator bg-warning me-2"></span>Tasks</span>'
                                    >
                                        Meilleures Categories
                                    </option>
                                </select>
                            </div>
                            {/* <!-- End Select --> */}
                        </div>

                        <div className="col-md-4 mt-3">
                            {/* <!-- Select --> */}
                            <div className="tom-select-custom">
                                <select
                                    className="js-select form-select w-full"
                                    autoComplete="off"
                                    id="eventColorLabel"
                                    data-hs-tom-select-options='{
                        "searchInDropdown": false,
                        "placeholder": "Select event color"
                      }'
                                >
                                    <option
                                        value=""
                                        data-option-template='<span className="d-flex align-items-center">Select event color</span>'
                                    >
                                        Notation des restaurants
                                    </option>
                                    <option
                                        value="fullcalendar-custom-event-hs-team"
                                        data-option-template='<span className="d-flex align-items-center"><span className="legend-indicator bg-primary me-2"></span>HS Team</span>'
                                    >
                                        1 Etoile
                                    </option>
                                    <option
                                        value="fullcalendar-custom-event-reminders"
                                        data-option-template='<span className="d-flex align-items-center"><span className="legend-indicator bg-danger me-2"></span>Reminders</span>'
                                    >
                                        2 Etoiles
                                    </option>
                                    <option
                                        value="fullcalendar-custom-event-tasks"
                                        data-option-template='<span className="d-flex align-items-center"><span className="legend-indicator bg-warning me-2"></span>Tasks</span>'
                                    >
                                        3 Etoiles
                                    </option>
                                    <option
                                        value="fullcalendar-custom-event-holidays"
                                        data-option-template='<span className="d-flex align-items-center"><span className="legend-indicator bg-dark me-2"></span>Success</span>'
                                    >
                                        4 Etoiles
                                    </option>
                                </select>
                            </div>
                            {/* <!-- End Select --> */}
                        </div>

                        <div className="mt-3 d-flex bd-highlight justify-content-start">
                            <div className="mx-2">
                                <a className="btn btn-primary" href="#">
                                    Filtrer
                                </a>
                            </div>

                            <div className="mx-2">
                                <a className="btn btn-white" href="#">
                                    Réinitialiser
                                </a>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <div className="container-fluid d-flex justify-content-end bd-highlight">
                <CreateDrinkModal />
            </div>

            <DrinkList />
        </>
    );
}

export default Drink;
