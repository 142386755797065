import React, {useState} from "react";
import DataTable from "../dataTable/DataTableBase";
import {Link} from "react-router-dom";
import {useGetRechargesQuery} from "./rechargesApiSlice";
import STATUS from "../../components/Shared/Status";

function RechargeList() {
    const [baseUrl, setBaseUrl] = useState("recharges");
    const [url, setUrl] = useState(baseUrl);

    const {
        data: response,
        isLoading,
        isSuccess,
        isError,
        error,
    } = useGetRechargesQuery({url});

    const columns = [
        {
            name: "Transaction",
            selector: "reference",
        },
        {
            name: "Montant",
            selector: "amount",
            type: "price",
        },
        {
            name: "Statut",
            selector: "status",
        },
        {
            name: "Date",
            selector: "created_at",
            type: "date",
        },
    ];

    let data = [];
    let meta = {};

    let content = "";
    if (isLoading) {
        content = <p>Loading ...</p>;
    } else if (isSuccess) {
        data = response.data.map((recharge) => ({
            id: recharge.id,
            // sender: recharge.sender.identity.fullName,
            status: STATUS[recharge.transaction.payment_status],
            amount: recharge.transaction.amount,
            reference: recharge.reference,
            created_at: recharge.created_at,
        }));

        meta = {
            currentPage: response.current_page,
            from: response.from,
            lastPage: response.last_page,
            perPage: response.per_page,
            total: response.total,
        };
    } else if (error) {
        content = <p>{JSON.stringify(error)}</p>;
    }

    const actions = (recharge) => {
        return (
            <div className="fw-bold d-flex">
                <Link to={`/recharge/${recharge.id}`}>
                    <button
                        className="btn btn-lg btn-ghost-secondary btn-icon rounded-circle"
                        type="button"
                    >
                        <i className="bi bi-eye"></i>
                    </button>
                </Link>
            </div>
        );
    };

    return (
        <div className="container">
            <DataTable
                columns={columns}
                data={data}
                actions={null}
                baseUrl={baseUrl}
                setUrl={setUrl}
                meta={meta}
            />
        </div>
    );
}

export default RechargeList;
