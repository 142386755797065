import axios from "axios";

const apiUrl = process.env.REACT_APP_BASE_URL;

const service = axios.create({
	baseURL: apiUrl,
	timeout: 60000
})

service.interceptors.request.use(config => {
	const jwtToken = localStorage.getItem('token') || null;

	if (jwtToken) {
		config.headers = {
			...((config.headers) ?? {}),
			Authorization: `Bearer ${jwtToken}`,
		}}

	return config
}, error => {

	Promise.reject(error)
})

// API respone interceptor
service.interceptors.response.use( (response) => {
	return response.data
}, (error) => {

	console.log(error.response.data.message)

	return Promise.reject(error);
});

export default service