import {apiSlice} from "../../app/api/apiSlice";

const dataForm = ({name = "", description = "", image = null}) => {
    var formdata = new FormData();
    formdata.append("name", name);
    formdata.append("description", description);
    formdata.append("image", image);
    return formdata;
};

const promotionsApiSlice = apiSlice.injectEndpoints({
    tagTypes: ["Promotions", "Promotion"],
    endpoints: (builder) => ({
        getPromotions: builder.query({
            query: ({url}) => url,
            providesTags: ["Promotions"],
        }),
        getPromotion: builder.query({
            query: ({promotionId}) => {
                return `promo-codes/${promotionId}`;
            },
            providesTags: ["Promotion"],
        }),
        getCreatePromotionData: builder.query({
            query: () => "promo-codes/create",
        }),
        addPromotion: builder.mutation({
            query: (promotion) => ({
                url: "promo-codes",
                method: "POST",
                body: promotion,
            }),
            invalidatesTags: ["Promotions"],
        }),
        updatePromotion: builder.mutation({
            query: (promotion) => ({
                url: `promo-codes/${promotion.id}`,
                method: "PUT",
                body: promotion,
            }),
            invalidatesTags: ["Promotions", "Promotion"],
        }),
        deletePromotion: builder.mutation({
            query: (promotionId) => ({
                url: `promo-codes/${promotionId}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Promotions"],
        }),
    }),
});

export const {
    useGetPromotionsQuery,
    useGetPromotionQuery,
    useGetCreatePromotionDataQuery,
    useAddPromotionMutation,
    useUpdatePromotionMutation,
    useDeletePromotionMutation,
} = promotionsApiSlice;
