import {createSlice} from "@reduxjs/toolkit";

const authSlice = createSlice({
    name: "auth",
    initialState: {
        user: null,
        token: null,
        roles: null,
        permissions: null,
        identity: null,
        staff: null,
        wallet: null,
    },
    reducers: {
        setCredentials: (state, action) => {
            const {user, accessToken} = action.payload;
            state.user = user;
            state.token = accessToken;
        },
        setUserInfo: (state, action) => {
            const {permissions, data, staff} = action.payload;
            state.permissions = permissions;
            state.identity = data.identity;
            state.roles = data.roles;
            state.staff = staff;
            state.wallet = data.wallet;
        },
        logOut: (state, action) => {
            state.user = null;
            state.token = null;
            state.roles = null;
            state.permissions = null;
            state.identity = null;
            state.staff = null;
        },
    },
});

export const {setCredentials, setUserInfo, logOut} = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.user;

export const selectCurrentToken = (state) => state.auth.token;

export const selectRoles = (state) => state.auth.roles;

export const selectPermissions = (state) => state.auth.permissions;

export const selectIdentity = (state) => state.auth.identity;

export const selectStaff = (state) => state.auth.staff;

export const selectUserWallet = (state) => state.auth.wallet;
