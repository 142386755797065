import React, {useEffect, useState} from "react";
import Swal from "sweetalert2";
import {
    useAddDrinkMutation,
    useGetCreateDrinkDataQuery,
    useUpdateDrinkMutation,
} from "./drinksApiSlice";
import Select from "react-select";
import {nanoid} from "@reduxjs/toolkit";

function UpdateDrinkModal({drink}) {
    const [name, setName] = useState(drink.name);
    const [price, setPrice] = useState(drink.price);
    const [image, setImage] = useState(null);
    const [restaurant, setRestaurant] = useState(null);
    const [restaurantsOptions, setRestaurantsOptions] = useState([]);
    const [drinkCategory, setDrinkCategory] = useState(null);
    const [drinkCategoriesOptions, setDrinkCategoriesOptions] = useState([]);

    const [updateDrink] = useUpdateDrinkMutation();

    const {
        data: response,
        isLoading,
        isSuccess,
        isError,
        error,
    } = useGetCreateDrinkDataQuery();

    useEffect(() => {
        if (isSuccess) {
            let formattedRestaurants = response.restaurants.map((restaurant) => ({
                ...restaurant,
                label: restaurant.name,
                value: restaurant.id,
            }));
            setRestaurantsOptions(formattedRestaurants);

            let formattedDrinkCategories = response.categories.map((category) => ({
                ...category,
                label: category.name,
                value: category.id,
            }));
            setDrinkCategoriesOptions(formattedDrinkCategories);
        }
    }, [isSuccess]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            name,
            price,
            drink_category_id: drinkCategory?.id ?? drink.drink_category_id,
            restaurant_id: restaurant?.id ?? drink.restaurant_id,
            _method: "put",
            id: drink.id,
        };
        console.log("UPADATE DATA:", data);
        updateDrink(data)
            .unwrap()
            .then((response) => {
                if (response?.error?.originalStatus === 500) {
                    Swal.fire({
                        title: "Erreur",
                        text: `Erreur du serveur lors de la création de la boisson `,
                        icon: "error",
                        toast: true,
                        position: "top-end",
                        timer: 3000,
                        timerProgressBar: true,
                    });
                } else {
                    Swal.fire({
                        title: "Succees",
                        text: `Boisson modifiée avec succès`,
                        icon: "success",
                        toast: true,
                        position: "top-end",
                        timer: 3000,
                        timerProgressBar: true,
                    });
                }
            })
            .catch((response) => {
                Swal.fire({
                    title: "Erreur",
                    text: `Erreur lors de la mise à jour de la boisson`,
                    icon: "error",
                    toast: true,
                    position: "top-end",
                    timer: 3000,
                    timerProgressBar: true,
                });
            });

        setName("");
        setPrice("");
        setDrinkCategory(null);
        setRestaurant(null);
    };

    return (
        <div>
            <button
                className="btn btn-ghost-secondary btn-icon rounded-circle btn-lg"
                type="button"
                data-bs-toggle="modal"
                data-bs-target={`#updateDrinkModal${drink.id}`}
            >
                <i className="bi bi-pencil fs-3"></i>
            </button>

            {/* <!-- Update Plan Modal --> */}
            <div
                className="modal fade"
                id={`updateDrinkModal${drink.id}`}
                tabIndex="-1"
                aria-labelledby="accountUpdatePlanModalLabel"
                role="dialog"
                aria-hidden="true"
            >
                <div
                    className="modal-dialog modal-dialog-centered modal-xs"
                    role="document"
                >
                    <div className="modal-content">
                        {/* <!-- Header --> */}
                        <div className="modal-header">
                            <h4 className="modal-title" id="accountUpdatePlanModalLabel">
                                Modifier une boisson
                            </h4>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        {/* <!-- End Header --> */}

                        {/* <!-- Body --> */}
                        <form
                            onSubmit={handleSubmit}
                            encType="multipart/form-data"
                            className="modal-body"
                        >
                            <div>
                                {/* <!-- Body --> */}
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-6 d-flex align-items-end mt-3">
                                            <input
                                                type="text"
                                                id="createDrinkName"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                className="form-control"
                                                placeholder="Nom de la boisson"
                                                aria-label="Nom de la boisson"
                                            />
                                        </div>

                                        <div className="col-md-6 mt-3">
                                            <input
                                                type="number"
                                                id="createDrinkPrice"
                                                value={price}
                                                onChange={(e) => setPrice(e.target.value)}
                                                className="form-control"
                                                placeholder="Prix de la boisson"
                                                aria-label="Prix de la boisson"
                                            />
                                        </div>

                                        <div className="col-md-6 mt-3">
                                            <p>Catégorie de boisson : </p>
                                            <Select
                                                // defaultValue={{
                                                //   ...drink.category,
                                                //   label: drink?.category?.name,
                                                //   value: drink?.category?.id,
                                                // }}
                                                name="drinkCategory"
                                                options={drinkCategoriesOptions}
                                                isClearable
                                                isSearchable
                                                onChange={setDrinkCategory}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                            />
                                        </div>

                                        <div className="col-md-6 mt-3">
                                            <p>Restaurant : </p>
                                            <Select
                                                name="restaurant"
                                                options={restaurantsOptions}
                                                isClearable
                                                isSearchable
                                                onChange={setRestaurant}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                            />
                                        </div>

                                        <div className="col-md-6 mt-3">
                                            <label htmlFor="mealCategoryImage" className="form-label">
                                                {image ? image.name : "Photo"}
                                            </label>

                                            <input
                                                type="file"
                                                id="image"
                                                value=""
                                                onChange={(e) => {
                                                    setImage(e.target.files[0]);
                                                }}
                                                className="form-control"
                                            />
                                        </div>

                                        <div className="mt-5 d-flex bd-highlight justify-content-start">
                                            <div className="mx-2">
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary"
                                                    data-bs-dismiss="modal"
                                                    aria-label="Close"
                                                >
                                                    Modifier
                                                </button>
                                            </div>

                                            <div className="mx-2">
                                                <button
                                                    type="button"
                                                    className="btn btn-white"
                                                    data-bs-dismiss="modal"
                                                    aria-label="Close"
                                                >
                                                    Annuler
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        {/* <!-- End Body --> */}
                    </div>
                </div>
            </div>
            {/* <!-- End Update Plan Modal --> */}
        </div>
    );
}

export default UpdateDrinkModal;
