import {apiSlice} from "../../app/api/apiSlice";

const dataForm = (data) => {
    const formdata = new FormData();
    const entriesAsArray = ["images", "options", "supplements", "tags"];

    for (const [key, value] of Object.entries(data)) {
        if (!entriesAsArray.includes(key)) {
            formdata.append(key, value);
        }
    }

    entriesAsArray
        .filter((x) => x !== "supplements")
        .forEach((entry) => {
            // check if entry exists in data
            if (entry in data) {
                data[entry].forEach((element, index) => {
                    formdata.append(`${entry}[${index}]`, element);
                });
            }
        });

    data["supplements"].forEach((element, index) => {
        formdata.append(
            `supplements[${index}][supplement_id]`,
            element.supplement_id
        );
        formdata.append(`supplements[${index}][price]`, element.price);
    });

    return formdata;
};

const mealsApiSlice = apiSlice.injectEndpoints({
    tagTypes: ["Meals"],
    endpoints: (builder) => ({
        getMealsStats: builder.query({
            query: () => "stats/meals",
            providesTags: ["Meals"],
        }),
        getMeals: builder.query({
            query: ({url}) => url,
            providesTags: ["Meals"],
        }),
        getMeal: builder.query({
            query: ({mealId}) => {
                return `meals/${mealId}`;
            },
            providesTags: ["Meal"],
        }),
        getCreateMealData: builder.query({
            query: () => "meals/create",
        }),
        addMeal: builder.mutation({
            query: (meal) => ({
                url: "meals",
                method: "POST",
                body: dataForm({...meal}),
            }),
            invalidatesTags: ["Meals"],
        }),
        updateMeal: builder.mutation({
            query: (meal) => ({
                url: `meals/${meal.id}`,
                method: "POST",
                body: dataForm({...meal}),
            }),
            invalidatesTags: ["Meals", "Meal"],
        }),
        deleteMeal: builder.mutation({
            query: (mealId) => ({
                url: `meals/${mealId}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Meals"],
        }),

        validateMeal: builder.mutation({
            query: (mealId) => ({
                url: `validate/meals`,
                method: "POST",
                body: {meal_id: mealId, _method: "put"},
            }),
            invalidatesTags: ["Meals"],
        }),

        toggleMeal: builder.mutation({
            query: (mealId) => ({
                url: `toggle-meals/${mealId}`,
                method: "POST",
                body: {_method: "put"},
            }),
            invalidatesTags: ["Meals"],
        }),

        // Meal promotion
        getCreateMealPromotionData: builder.query({
            query: () => "promo-codes/create",
        }),
        addMealPromotion: builder.mutation({
            query: (promotion) => ({
                url: "promo-codes",
                method: "POST",
                body: promotion,
            }),
            invalidatesTags: ["Promotions"],
        }),
    }),
});

export const {
    useGetMealsStatsQuery,
    useGetMealsQuery,
    useGetMealQuery,
    useGetCreateMealDataQuery,
    useAddMealMutation,
    useUpdateMealMutation,
    useDeleteMealMutation,
    useValidateMealMutation,
    useToggleMealMutation,

    // Meal promotion
    useGetCreateMealPromotionDataQuery,
    useAddMealPromotionMutation,
} = mealsApiSlice;
