import React, {useCallback, useEffect, useState} from "react";
import DataTable from "../dataTable/DataTableBase";
import {Link} from "react-router-dom";
import {useGetRestaurantsQuery, useToggleRestaurantMutation} from "./restaurantsApiSlice";
import DeleteRestaurantModal from "./DeleteRestaurantModal";
import ValidateRestaurantModal from "./ValidateRestaurantModal";
import CreateRestaurantPromotion from "./CreateRestaurantPromotion";
import debounce from "lodash.debounce";
import Swal from "sweetalert2";

function RestaurantList() {
    const [baseUrl, setBaseUrl] = useState("restaurants");
    const [url, setUrl] = useState(baseUrl);
    const [canSelect, setCanSelect] = useState(false);
    const [search, setSearch] = useState("");

    const {
        data: response,
        isLoading,
        isSuccess,
        isError,
        error,
    } = useGetRestaurantsQuery({url});

    const debouncedSetUrl = useCallback(
        debounce((search, baseUrl) => setUrl(baseUrl + "?search=" + search), 500),
        []
    );


    const [toggleRestaurant] = useToggleRestaurantMutation();

    useEffect(() => {
        debouncedSetUrl(search, baseUrl);
    }, [search, baseUrl]);

    const columns = [
        {
            name: "Logo",
            selector: "logo",
            sortable: true,
            type: "image",
        },
        /* {
            name: "Bannière",
            selector: "banner",
            sortable: true,
            type: "image",
        }, */
        {
            name: "Nom",
            selector: "name",
            sortable: true,
        },
        {
            name: "Disponible",
            selector: "is_available",
            type: "boolean",
        },
        {
            name: "Statut",
            selector: "status",
            type: "status",
        },
        {
            name: "Adresse",
            selector: "address",
        },
    ];

    let data = [];
    let meta = {};

    let content = "";
    if (isLoading) {
        content = <p>Loading ...</p>;
    } else if (isSuccess) {
        data = response.data.map((restaurant) => ({
            id: restaurant.id,
            name: restaurant.name,
            status: restaurant.status,
            address: restaurant.address,
            validated_by: restaurant.validated_by,
            logo: restaurant.logo_url,
            /* banner: restaurant.banner_url, */
            is_available: restaurant.is_available,
        }));

        meta = {
            currentPage: response.current_page,
            from: response.from,
            lastPage: response.last_page,
            perPage: response.per_page,
            total: response.total,
        };
    } else if (error) {
        content = <p>{JSON.stringify(error)}</p>;
    }

    const toggleRestaurantFunction = (is_available, id) => {
        Swal.fire({
            title: "Confirmation",
            text: `Voulez-vous vraiment ${is_available ? "désactiver" : 'activer'}  ce restaurant ?`,
            icon: "question",
            confirmButtonText: "Oui",
            denyButtonText: "Non",
            showDenyButton: true,
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "Traitement...",
                    text: "Veuillez patienter pendant le traitement de votre requête",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                toggleRestaurant(id)
                    .unwrap()
                    .then((response) => {
                        Swal.fire({
                            title: "Succees",
                            text: `${is_available ? "Désactivation" : "Activation"} effectuée avec succès`,
                            icon: "success",
                            toast: true,
                            position: "top-end",
                            timer: 3000,
                            timerProgressBar: true,
                        });
                    })
                    .catch((response) => {
                        Swal.fire({
                            title: "Erreur",
                            text: `Erreur lors de la ${is_available ? "désactivation" : "activation"} du restaurant`,
                            icon: "error",
                            toast: true,
                            position: "top-end",
                            timer: 3000,
                            timerProgressBar: true,
                        });
                    });
            } else {
                Swal.close()
            }
        })
    }

    const actions = (restaurant) => {
        return (
            <div className="fw-bold d-flex">
                {!restaurant.validated_by && (
                    <ValidateRestaurantModal restaurant={restaurant}/>
                )}

                <button
                    onClick={() => toggleRestaurantFunction(restaurant.is_available, restaurant.id)}
                    title={`${restaurant.is_available ? "Désactiver" : "Activer"} le restaurant`}
                    className="btn btn-ghost-secondary btn-icon rounded-circle btn-lg"
                    type="button"
                >
                    <i className="bi bi-arrow-left-right"></i>
                </button>

                <Link to={`/restaurant/${restaurant.id}`}>
                    <button
                        className="btn btn-ghost-secondary btn-icon rounded-circle btn-lg"
                        type="button"
                        title="Consulter le détail"
                    >
                        <i className="bi bi-eye fs-3"></i>
                    </button>
                </Link>

                <Link to={`/restaurant/edit/${restaurant.id}`}>
                    <button
                        className="btn btn-ghost-secondary btn-icon rounded-circle btn-lg"
                        type="button"
                        title="Modifier"
                    >
                        <i className="bi bi-pencil fs-3"></i>
                    </button>
                </Link>
                <DeleteRestaurantModal restaurant={restaurant}/>
            </div>
        );
    };

    return (
        <>
            <div className="container-fluid d-flex justify-content-end bd-highlight">
                <div className="m-4">
                    <input
                        type="search"
                        className="js-form-search form-control"
                        placeholder="Rechercher"
                        aria-label="Rechercher"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>

                <div className="m-4">
                    <Link to="/restaurant/create">
                        {/* <button
                            className="btn btn-ghost-secondary btn-icon rounded-circle btn-lg"
                            type="button"
                        >
                            <i className="bi bi-plus fs-3"></i>
                        </button> */}
                        <button
                            className="btn btn-lg btn-primary border"
                            type="button"
                        >
                            <i className="bi-building"></i>
                            Créer Restaurant
                        </button>
                    </Link>
                </div>

                {/* <div className="m-4">
                    <button
                        className="btn btn-lg btn-ghost-secondary border"
                        type="button"
                        onClick={() => setCanSelect(!canSelect)}
                    >
                        Sélectionner
                    </button>
                </div> */}

               {/*  <CreateRestaurantPromotion
                    restaurants={data}
                    setCanSelect={setCanSelect}
                /> */}
            </div>
            <div className="container">
                <DataTable
                    columns={columns}
                    data={data}
                    actions={actions}
                    baseUrl={baseUrl}
                    setUrl={setUrl}
                    meta={meta}
                    canSelect={canSelect}
                />
            </div>
        </>
    );
}

export default RestaurantList;
