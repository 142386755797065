const STATUS = {
    accepted_delivery: (
        <span class="badge bg-soft-success text-info">
      <span class="legend-indicator bg-success"></span>ACCEPTÉE PAR LIVREUR
    </span>
    ),
    activated: (
        <span class="badge bg-soft-success text-info">
      <span class="legend-indicator bg-success"></span>ACTIVÉ
    </span>
    ),
    deactivated: (
        <span class="badge bg-soft-danger text-danger">
      <span class="legend-indicator bg-danger"></span>DÉSACTIVÉ
    </span>
    ),
    delivered: (
        <span class="badge bg-soft-success text-info">
      <span class="legend-indicator bg-success"></span>LIVRÉE
    </span>
    ),
    fulfilledFulfilled: (
        <span class="badge bg-soft-info text-info">
      <span class="legend-indicator bg-info"></span>Fulfilled
    </span>
    ),
    pending: (
        <span class="badge bg-soft-warning text-warning">
      <span class="legend-indicator bg-warning"></span>EN ATTENTE
    </span>
    ),
    unFulfilledFulfilled: (
        <span class="badge bg-soft-dark text-dark">
      <span class="legend-indicator bg-dark"></span>Unfulfilled
    </span>
    ),
    ready: (
        <span class="badge bg-soft-success text-success rounded-pill">
      <span class="legend-indicator bg-success"></span>PRÊT
    </span>
    ),
    accepted: (
        <span class="badge bg-soft-primary text-primary rounded-pill">
      <span class="legend-indicator bg-primary"></span>ACCEPTÉ
    </span>
    ),
    leader: (
        <span class="badge bg-soft-success text-success rounded-pill">
      <span class="legend-indicator bg-success"></span>DIRIGEANT
    </span>
    ),
    Approved: (
        <span class="badge bg-soft-success text-primary rounded-pill">
      <span class="legend-indicator bg-primary"></span>APPROUVÉ
    </span>
    ),
    approved: (
        <span class="badge bg-soft-success text-primary rounded-pill">
      <span class="legend-indicator bg-primary"></span>APPROUVÉ
    </span>
    ),
    success: (
        <span class="badge bg-soft-success text-primary rounded-pill">
      <span class="legend-indicator bg-primary"></span>SUCCÈS
    </span>
    ),
    valid: (
        <span class="badge bg-soft-success text-primary rounded-pill">
      <span class="legend-indicator bg-primary"></span>VALIDE
    </span>
    ),
    invalid: (
        <span class="badge bg-warning text-black rounded-pill">
      <span class="legend-indicator bg-black"></span>INVALIDE
    </span>
    ),
    processing: (
        <span class="badge bg-soft-warning text-warning">
      <span class="legend-indicator bg-warning"></span>EN PRÉPARATION
    </span>
    ),
    recupered: (
        <span class="badge bg-soft-success text-primary rounded-pill">
      <span class="legend-indicator bg-primary"></span>RÉCUPÉRÉ
    </span>
    ),
    cancelled: (
        <span class="badge bg-soft-dark text-dark">
      <span class="legend-indicator bg-dark"></span>Annulé
    </span>
    ),
    failed: (
        <span class="badge bg-soft-dark text-dark">
      <span class="legend-indicator bg-dark"></span>Echoué
    </span>
    ),
    refunded: (
        <span class="badge bg-soft-dark text-dark">
      <span class="legend-indicator bg-dark"></span>Remboursé
    </span>
    ),
    none: "",
};

export default STATUS;
